import { useContext } from 'react';
import { UserContext } from '../../contexts';

import { Navigate, Outlet } from 'react-router-dom';
import { publicRoutes } from '../../routes';



const Authguard = () => {

    const { user, token } = useContext(UserContext);

    return (
        user.id && token ? <Outlet /> : <Navigate replace to={publicRoutes.LOGIN} />
    )
};

export default Authguard;
