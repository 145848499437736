import { Header, Sidebar, Footer } from '../../components';
import style from './main.module.scss';


const MainLayOut = ({children}) => {
    return (
        <div className={style.main_container}>
            <Header />
            <Sidebar>
                <div className={style.content_container}>
                    {children}
                </div>
            </Sidebar>
            <Footer />
        </div>
    )
};

export default MainLayOut;
