import { GmLogo } from '../../assets';

import style from './alt.module.scss';



const AltLayout = ( { children } ) => {

    return (
        <>
            <div className={style.header}>
                <div style={{cursor: 'pointer'}}>
                    <GmLogo width='320px' light='white' dark='#ff5500' />
                </div>
            </div>

            <div className={style.main_container}>
                { children }
            </div>
        </>
    );
}

export default AltLayout;