const GmIcon = ({ clase, width="620px", dark, light="#FF5B00"}) => {
    return (
        <div className={clase}>

        <svg x="0px" y="0px" viewBox="0 0 102.9 43.7" xmlSpace="preserve" width={width}>
            <path fill={light} d="M25,2.3h12.9c-0.5,0.9-0.8,1.8-0.9,2.6c-0.1,0.9-0.2,2.4-0.2,4.5v21.4c0,2.2-0.1,3.9-0.4,5c-0.2,1.1-0.7,2.1-1.4,3
                c-1,1.3-2,2.1-3.3,2.5c-1.2,0.4-3.4,0.6-6.7,0.6H3.4l-1-6.9c0.7,0.7,1.6,1.1,2.5,1.3s2.5,0.3,4.7,0.3h9.8c1.8,0,2.9-0.2,3.4-0.7
                c0.5-0.4,0.7-1.4,0.7-3v-4.1c-0.4,0.8-1,1.4-1.7,1.7s-1.9,0.4-3.5,0.4h-7.9c-2.1,0-3.5-0.1-4.4-0.3s-1.7-0.7-2.5-1.3
                C1.9,27.7,1,25.5,1,22.6V10.5c0-2.6,0.7-4.6,2.1-6.1C4.5,3,6.4,2.3,9,2.3h11c1.3,0,2.3,0.1,2.9,0.4c0.6,0.3,1.2,0.9,1.8,1.7L25,2.3z
                M23.6,22.2V11.1c0-1,0-1.7-0.1-2s-0.3-0.6-0.6-0.9c-0.4-0.4-1.2-0.5-2.2-0.5h-3.8c-0.7,0-1.2,0-1.4,0.1S15,8,14.8,8.2
                c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.4-0.1,1-0.1,1.9v11c0,2.3,0.9,3.4,2.8,3.3h3.8c1.1,0,1.9-0.2,2.3-0.7
                C23.4,24.4,23.6,23.5,23.6,22.2z"/>
            <path fill={dark} d="M55.9,2.3l0.3,2.8c0.2-1.1,0.6-1.8,1.1-2.2s1.3-0.6,2.5-0.6h13.7c1.8,0,3,0.2,3.6,0.5s1.1,1.1,1.5,2.3
                c0.3-1.1,0.7-1.9,1.2-2.3c0.5-0.4,1.4-0.6,2.5-0.6h10.5c1.7,0,3.1,0.2,4,0.5s1.8,0.9,2.5,1.8c0.7,0.9,1.3,1.9,1.6,3.1
                c0.3,1.2,0.4,2.8,0.4,5v11.1c0,2.9,0,4.6,0.1,5.1s0.3,1.2,0.8,2.1H87c0.5-0.9,0.8-1.8,0.9-2.6c0.1-0.9,0.2-2.4,0.2-4.6V11.4
                c0-1.5-0.2-2.4-0.6-2.9s-1.1-0.7-2.3-0.7h-3.3c-1.2,0-2,0.2-2.4,0.6S79,9.7,79,11.1v12.7c0,2.2,0.1,3.7,0.2,4.6
                c0.1,0.8,0.4,1.7,0.9,2.6H64.7c0.5-0.9,0.8-1.8,0.9-2.6c0.1-0.9,0.2-2.4,0.2-4.6V11.3c0-1.3-0.2-2.3-0.6-2.8c-0.4-0.5-1-0.8-2-0.8
                h-4c-1,0-1.7,0.2-2,0.7c-0.4,0.5-0.5,1.3-0.5,2.5v12.8c0,2.2,0.1,3.7,0.2,4.6c0.1,0.9,0.4,1.7,0.9,2.6H42.4c0.5-0.9,0.8-1.8,0.9-2.6
                c0.1-0.9,0.2-2.4,0.2-4.6V9.5c0-2.2-0.1-3.7-0.2-4.6c-0.1-0.9-0.4-1.7-0.9-2.6H55.9z"/>
        </svg>
        </div>
    );
};

export default GmIcon;
