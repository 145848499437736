import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;



const verifyEmailAccountAPI = async (token) => {
    try {
        const verifyEmail = await fetch(`${apipath}/subscriptions/verifyemailaccount`, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await verifyEmail.json();
    }
    catch (error) {
        return {error: true, message: 'Ocurrió un error al intentar verificar la dirección de email. Por favor intentá nuevamente haciendo click en el botón de verificación del mail que recibiste.'};
    }
};


export { verifyEmailAccountAPI };
