import { useNavigate } from "react-router-dom";


const useNavigateTo = () => {

    const navigate = useNavigate();

    const goTo = (path) => {
        navigate(path, {replace: true})
    };

    return [ goTo ];
};

export default useNavigateTo;