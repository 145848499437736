const SectionIcon = ({width='36px', height='36px', fill}) => {
    return (
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 36 36" xmlSpace="preserve">
            <path fill={fill} d="M33.4,24.4H17.2c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h16.2c1.4,0,2.5,1.1,2.5,2.5S34.8,24.4,33.4,24.4z"/>
            <path fill={fill} d="M33.4,35.8H17.2c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h16.2c1.4,0,2.5,1.1,2.5,2.5S34.8,35.8,33.4,35.8z"/>
            <path fill={fill} d="M2.7,35.8c-0.6,0-1.2-0.2-1.7-0.7c-1-1-1.1-2.5-0.1-3.6L5,27.1l-4.2-4.3c-1-1-1-2.6,0-3.6c1-1,2.6-1,3.6,0
                l5.9,6c0.9,1,1,2.5,0,3.5L4.5,35C4,35.6,3.4,35.8,2.7,35.8z"/>
            <path fill={fill} d="M28.2,16H7.7c-4.2,0-7.5-3.4-7.5-7.5V7.8c0-4.2,3.4-7.5,7.5-7.5h20.5c4.2,0,7.5,3.4,7.5,7.5v0.7
                C35.7,12.7,32.4,16,28.2,16z M7.7,4.9c-1.6,0-2.9,1.3-2.9,2.9v0.7c0,1.6,1.3,2.9,2.9,2.9h20.5c1.6,0,2.9-1.3,2.9-2.9V7.8
                c0-1.6-1.3-2.9-2.9-2.9H7.7z"/>
        </svg>
    )
};

export default SectionIcon;