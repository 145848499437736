const GmLogo = ({clase, width="620px", dark, light="#FF5B00"}) => {
    return (
        <div className={clase}>
            <svg x="0px" y="0px" viewBox="0 0 608.4 61" xmlSpace="preserve" width={width}>
                <g>
                    <path fill={light} d="M29.3,12.9h15.1c-0.5,1-0.9,2.1-1.1,3.1c-0.2,1-0.2,2.8-0.2,5.4v25.2c0,2.6-0.1,4.5-0.4,5.8c-0.3,1.3-0.8,2.5-1.7,3.6
                        c-1.1,1.5-2.4,2.5-3.8,2.9c-1.4,0.4-4.1,0.7-7.9,0.7H3.9l-1.1-8.2c0.9,0.8,1.8,1.2,2.9,1.5c1.1,0.2,2.9,0.3,5.6,0.3h11.6
                        c2.1,0,3.4-0.3,4-0.8c0.6-0.5,0.9-1.7,0.9-3.6v-4.8c-0.5,1-1.1,1.6-2,2c-0.8,0.3-2.2,0.5-4.1,0.5h-9.3c-2.4,0-4.2-0.1-5.2-0.4
                        c-1-0.3-2-0.8-3-1.6c-2-1.8-3.1-4.4-3.1-7.8V22.6c0-3.1,0.8-5.5,2.5-7.2c1.6-1.7,4-2.6,7-2.6h12.9c1.5,0,2.7,0.2,3.4,0.5
                        c0.7,0.3,1.4,1,2.1,2.1L29.3,12.9z M27.7,36.3v-13c0-1.2,0-2-0.1-2.3c-0.1-0.3-0.3-0.7-0.7-1c-0.5-0.4-1.4-0.6-2.6-0.6h-4.5
                        c-0.8,0-1.4,0-1.7,0.1c-0.3,0.1-0.6,0.3-0.8,0.5c-0.3,0.3-0.5,0.7-0.6,1.1c-0.1,0.4-0.1,1.2-0.1,2.3v13c0,2.7,1.1,4,3.3,3.9h4.5
                        c1.3,0,2.2-0.3,2.7-0.8C27.5,38.9,27.7,37.9,27.7,36.3z"/>
                    <path fill={light} d="M49.8,12.9h28.9c2.2,0,3.8,0.2,4.8,0.5c1,0.3,2.1,1,3.1,2c1.9,1.9,2.9,4.3,2.9,7.2v15.6c0,2.6,0.1,4.3,0.2,5.4
                        c0.2,1,0.5,2,1.1,3.1H75.3c-0.5-0.9-0.8-2.1-0.9-3.6c-0.3,1.2-1,2.1-1.9,2.7c-0.9,0.6-2.2,0.9-3.8,0.9H57.1c-1.9,0-3.4-0.1-4.5-0.4
                        c-1-0.3-2-0.8-2.8-1.5c-0.9-0.8-1.5-1.7-1.9-2.9c-0.4-1.2-0.6-2.7-0.6-4.5v-5.7c0-2.5,0.7-4.4,2.1-5.8c1.4-1.3,3.4-2,6-2h14.8
                        c1.3,0,2.2-0.1,2.6-0.2c0.8-0.3,1.1-1,1.1-2.2c0-0.8-0.2-1.4-0.7-1.7c-0.5-0.3-1.4-0.5-2.7-0.5H55.4c-2.6,0-4.8,0.5-6.7,1.6
                        L49.8,12.9z M73.9,30.3h-7.5c-1.4,0-2.3,0.3-2.9,0.9c-0.5,0.6-0.8,1.7-0.8,3.4V36c0,1.6,0.3,2.7,0.8,3.3c0.5,0.6,1.5,0.9,2.9,0.9
                        h3.8c1.4,0,2.4-0.3,2.9-0.9c0.5-0.6,0.8-1.7,0.8-3.3V30.3z"/>
                    <path fill={light} d="M128.4,12.9l1.3,8.1c-0.9-0.6-1.8-1.1-2.7-1.4c-0.9-0.3-2.1-0.4-3.5-0.4H114c-1.7,0-2.8,0.3-3.5,0.8
                        c-0.6,0.5-0.9,1.6-0.9,3.1v13.4c0,1.4,0.3,2.3,1,2.8c0.6,0.5,1.8,0.8,3.6,0.8h8.8c1.5,0,2.7-0.1,3.6-0.4c0.9-0.3,2-0.8,3.1-1.5
                        l-1.3,8.3h-23.2c-0.5,0-2-0.2-4.4-0.4c-1.9-0.4-3.5-1.4-4.8-3.1c-1.2-1.7-1.9-3.6-1.9-5.8V22c0.5-6.2,4-9.2,10.6-9.1H128.4z"/>
                    <path d="M174,33h-26.2v2.8c0,1.8,0.3,3,0.9,3.6c0.6,0.6,1.9,0.9,3.9,0.9h14.1c3.1-0.1,5.4-0.6,6.8-1.5l-1.1,7.9h-28.2
                        c-2.4,0-4.3-0.2-5.5-0.5c-1.3-0.3-2.4-1-3.3-1.9c-1.9-1.8-2.9-4.8-3-9V24.3c-0.1-7.9,3.5-11.7,10.9-11.4h19.5
                        c2.6,0,4.5,0.2,5.7,0.5c1.2,0.4,2.3,1,3.2,2.1c0.9,1,1.5,2.1,1.9,3.5c0.4,1.3,0.5,3.1,0.5,5.3V33z M158.7,26.7V23
                        c0-1.6-0.2-2.6-0.5-3.1c-0.3-0.4-1.1-0.7-2.3-0.7h-5c-0.9,0-1.4,0-1.6,0.1c-0.2,0.1-0.5,0.2-0.7,0.5c-0.4,0.5-0.6,1.6-0.6,3.3v3.6
                        H158.7z" fill={light}/>
                    <path d="M209.6,12.9v6.4h-13.1v15.6c0,1.4,0.1,2.5,0.2,3s0.3,1,0.7,1.4c0.6,0.6,1.9,0.9,3.8,0.9h4.2c2.7,0,4.8-0.6,6.3-1.8
                        l-1.2,8.2h-16.4c-3.2,0-5.5-0.2-6.8-0.5c-1.4-0.4-2.6-1.1-3.6-2.2c-1-1.1-1.7-2.3-2-3.6c-0.3-1.3-0.5-3.7-0.5-7.2V19.3h-4.8v-6.4
                        h4.8V6l15.5-0.7v7.6H209.6z" fill={light}/>
                    <path d="M216.2,12.9h28.9c2.2,0,3.8,0.2,4.8,0.5c1,0.3,2.1,1,3.1,2c1.9,1.9,2.9,4.3,2.9,7.2v15.6c0,2.6,0.1,4.3,0.2,5.4
                        c0.2,1,0.5,2,1.1,3.1h-15.4c-0.5-0.9-0.8-2.1-0.9-3.6c-0.3,1.2-1,2.1-1.9,2.7c-0.9,0.6-2.2,0.9-3.8,0.9h-11.6
                        c-1.9,0-3.4-0.1-4.5-0.4c-1-0.3-2-0.8-2.8-1.5c-0.9-0.8-1.5-1.7-1.9-2.9c-0.4-1.2-0.6-2.7-0.6-4.5v-5.7c0-2.5,0.7-4.4,2.1-5.8
                        s3.4-2,6-2h14.8c1.3,0,2.2-0.1,2.6-0.2c0.8-0.3,1.1-1,1.1-2.2c0-0.8-0.2-1.4-0.7-1.7c-0.5-0.3-1.4-0.5-2.7-0.5h-15.1
                        c-2.6,0-4.8,0.5-6.7,1.6L216.2,12.9z M240.4,30.3h-7.5c-1.4,0-2.3,0.3-2.9,0.9c-0.5,0.6-0.8,1.7-0.8,3.4V36c0,1.6,0.3,2.7,0.8,3.3
                        c0.5,0.6,1.5,0.9,2.9,0.9h3.8c1.4,0,2.4-0.3,2.9-0.9c0.5-0.6,0.8-1.7,0.8-3.3V30.3z" fill={light}/>
                    <path fill={dark} d="M276.1,12.9l0.4,3.3c0.3-1.3,0.7-2.1,1.4-2.6c0.6-0.5,1.6-0.7,2.9-0.7H297c2.1,0,3.5,0.2,4.3,0.6
                        c0.7,0.4,1.3,1.3,1.7,2.7c0.3-1.3,0.8-2.2,1.4-2.7c0.6-0.5,1.6-0.7,3-0.7h12.4c2.1,0,3.6,0.2,4.7,0.6c1.1,0.4,2.1,1.1,3,2.2
                        c0.9,1,1.5,2.2,1.8,3.6c0.3,1.4,0.5,3.3,0.5,5.8v13c0,3.4,0,5.4,0.1,6c0.1,0.6,0.4,1.4,1,2.5H313c0.5-1,0.9-2.1,1.1-3.1
                        c0.2-1,0.2-2.8,0.2-5.4V23.6c0-1.7-0.2-2.9-0.7-3.4c-0.5-0.6-1.3-0.9-2.7-0.9h-3.9c-1.5,0-2.4,0.2-2.8,0.7
                        c-0.4,0.5-0.6,1.6-0.6,3.2v14.9c0,2.6,0.1,4.4,0.2,5.4c0.1,1,0.5,2,1,3.1h-18.1c0.6-1,0.9-2.1,1.1-3.1c0.2-1,0.2-2.8,0.2-5.4V23.5
                        c0-1.6-0.2-2.7-0.7-3.3c-0.4-0.6-1.2-0.9-2.3-0.9h-4.7c-1.2,0-2,0.3-2.4,0.8c-0.4,0.5-0.6,1.5-0.6,3v15c0,2.6,0.1,4.3,0.2,5.4
                        c0.2,1,0.5,2.1,1.1,3.1h-18.1c0.5-1,0.9-2.1,1.1-3.1c0.2-1,0.2-2.8,0.2-5.4V21.4c0-2.6-0.1-4.3-0.2-5.4c-0.2-1-0.5-2-1.1-3.1H276.1
                        z"/>
                    <path fill={dark} d="M336.8,12.9h28.9c2.2,0,3.8,0.2,4.8,0.5c1,0.3,2.1,1,3.1,2c1.9,1.9,2.9,4.3,2.9,7.2v15.6
                        c0,2.6,0.1,4.3,0.2,5.4c0.2,1,0.5,2,1.1,3.1h-15.4c-0.5-0.9-0.8-2.1-0.9-3.6c-0.3,1.2-1,2.1-1.9,2.7c-0.9,0.6-2.2,0.9-3.8,0.9
                        h-11.6c-1.9,0-3.4-0.1-4.5-0.4c-1-0.3-2-0.8-2.8-1.5c-0.9-0.8-1.5-1.7-1.9-2.9c-0.4-1.2-0.6-2.7-0.6-4.5v-5.7
                        c0-2.5,0.7-4.4,2.1-5.8c1.4-1.3,3.4-2,6-2h14.8c1.3,0,2.2-0.1,2.6-0.2c0.8-0.3,1.1-1,1.1-2.2c0-0.8-0.2-1.4-0.7-1.7
                        c-0.5-0.3-1.4-0.5-2.7-0.5h-15.1c-2.6,0-4.8,0.5-6.7,1.6L336.8,12.9z M361,30.3h-7.5c-1.4,0-2.3,0.3-2.9,0.9
                        c-0.5,0.6-0.8,1.7-0.8,3.4V36c0,1.6,0.3,2.7,0.8,3.3c0.5,0.6,1.5,0.9,2.9,0.9h3.8c1.4,0,2.4-0.3,2.9-0.9c0.5-0.6,0.8-1.7,0.8-3.3
                        V30.3z"/>
                    <path fill={dark} d="M396.5,12.9l0.4,5.5c0.4-1.3,0.8-2.3,1.2-3c0.4-0.6,1-1.2,1.8-1.6c1.3-0.7,3-1,5.2-1h7.8v11.1
                        c-0.7-0.7-1.6-1.2-2.5-1.5c-0.9-0.3-2.3-0.4-4-0.4h-3.2c-1.7,0-3,0.4-3.9,1.3c-0.9,0.9-1.4,2.1-1.4,3.7v11c0,2.6,0.1,4.3,0.2,5.4
                        c0.2,1,0.5,2,1.1,3.1h-18.1c0.5-1,0.9-2.1,1.1-3.1c0.2-1,0.2-2.8,0.2-5.4V21.4c0-2.6-0.1-4.3-0.2-5.4c-0.2-1-0.5-2.1-1.1-3.1H396.5
                        z"/>
                    <path fill={dark} d="M432,12.9v25.2c0,2.6,0.1,4.4,0.2,5.4c0.2,1,0.5,2,1.1,3.1h-18.1c0.5-1,0.9-2.1,1.1-3.1c0.2-1,0.2-2.8,0.2-5.4
                        V21.4c0-2.6-0.1-4.4-0.2-5.4c-0.2-1-0.5-2-1.1-3.1H432z M432,1.1v8.5h-15.5V1.1H432z"/>
                    <path fill={dark} d="M452,12.9l0.7,3.4c0.6-1.3,1.4-2.2,2.2-2.7c0.8-0.5,1.9-0.7,3.5-0.7h11.6c1.8,0,3.3,0.2,4.5,0.7
                        c1.1,0.4,2.2,1.2,3.1,2.3c0.9,1.1,1.5,2.2,1.9,3.5c0.4,1.2,0.6,2.8,0.6,4.7v14.1c0,2.6,0.1,4.4,0.2,5.4c0.2,1,0.5,2,1.1,3.1h-18.1
                        c0.5-1,0.9-2,1.1-3.1c0.2-1,0.2-2.8,0.2-5.4V23.5c0-1.7-0.2-2.8-0.6-3.4c-0.4-0.5-1.3-0.8-2.7-0.8h-4.8c-1,0-1.7,0.2-2.1,0.7
                        c-0.4,0.5-0.6,1.3-0.6,2.6v15.5c0,2.6,0.1,4.3,0.2,5.4c0.2,1,0.5,2.1,1.1,3.1h-18.1c0.5-1,0.9-2.1,1.1-3.1c0.2-1,0.2-2.8,0.2-5.4
                        V21.4c0-2.6-0.1-4.3-0.2-5.4c-0.2-1-0.5-2-1.1-3.1H452z"/>
                    <path fill={dark} d="M526.5,33h-26.2v2.8c0,1.8,0.3,3,0.9,3.6c0.6,0.6,1.9,0.9,3.9,0.9h14.1c3.1-0.1,5.4-0.6,6.8-1.5l-1.1,7.9
                        h-28.2c-2.4,0-4.3-0.2-5.5-0.5s-2.4-1-3.3-1.9c-1.9-1.8-2.9-4.8-3-9V24.3c-0.1-7.9,3.5-11.7,10.9-11.4h19.5c2.6,0,4.5,0.2,5.7,0.5
                        c1.2,0.4,2.3,1,3.2,2.1c0.9,1,1.5,2.1,1.9,3.5c0.4,1.3,0.5,3.1,0.5,5.3V33z M511.2,26.7V23c0-1.6-0.2-2.6-0.5-3.1
                        c-0.3-0.4-1.1-0.7-2.3-0.7h-5c-0.9,0-1.4,0-1.6,0.1c-0.2,0.1-0.5,0.2-0.7,0.5c-0.4,0.5-0.6,1.6-0.6,3.3v3.6H511.2z"/>
                    <path fill={dark} d="M545.4,12.9l0.4,5.5c0.4-1.3,0.8-2.3,1.2-3c0.4-0.6,1-1.2,1.8-1.6c1.3-0.7,3-1,5.2-1h7.8v11.1
                        c-0.7-0.7-1.6-1.2-2.5-1.5c-0.9-0.3-2.3-0.4-4-0.4h-3.2c-1.7,0-3,0.4-3.9,1.3c-0.9,0.9-1.4,2.1-1.4,3.7v11c0,2.6,0.1,4.3,0.2,5.4
                        c0.2,1,0.5,2,1.1,3.1h-18.1c0.5-1,0.9-2.1,1.1-3.1c0.2-1,0.2-2.8,0.2-5.4V21.4c0-2.6-0.1-4.3-0.2-5.4c-0.2-1-0.5-2.1-1.1-3.1H545.4
                        z"/>
                    <path fill={dark} d="M565.9,12.9h28.9c2.2,0,3.8,0.2,4.8,0.5c1,0.3,2.1,1,3.1,2c1.9,1.9,2.9,4.3,2.9,7.2v15.6
                        c0,2.6,0.1,4.3,0.2,5.4c0.2,1,0.5,2,1.1,3.1h-15.4c-0.5-0.9-0.8-2.1-0.9-3.6c-0.3,1.2-1,2.1-1.9,2.7c-0.9,0.6-2.2,0.9-3.8,0.9
                        h-11.6c-1.9,0-3.4-0.1-4.5-0.4c-1-0.3-2-0.8-2.8-1.5c-0.9-0.8-1.5-1.7-1.9-2.9c-0.4-1.2-0.6-2.7-0.6-4.5v-5.7
                        c0-2.5,0.7-4.4,2.1-5.8s3.4-2,6-2h14.8c1.3,0,2.2-0.1,2.6-0.2c0.8-0.3,1.1-1,1.1-2.2c0-0.8-0.2-1.4-0.7-1.7
                        c-0.5-0.3-1.4-0.5-2.7-0.5h-15.1c-2.6,0-4.8,0.5-6.7,1.6L565.9,12.9z M590.1,30.3h-7.5c-1.4,0-2.3,0.3-2.9,0.9
                        c-0.5,0.6-0.8,1.7-0.8,3.4V36c0,1.6,0.3,2.7,0.8,3.3c0.5,0.6,1.5,0.9,2.9,0.9h3.8c1.4,0,2.4-0.3,2.9-0.9c0.5-0.6,0.8-1.7,0.8-3.3
                        V30.3z"/>
                </g>
            </svg>
        </div>
    );
};

export default GmLogo;
