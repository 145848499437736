const DeleteIcon = ({ width = "22px", fill }) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 25.2 25.2" xmlSpace="preserve" width={width} fill={fill}>
            <polygon points="16.5,2.5 16.5,0.4 8.6,0.4 8.6,2.5 2.3,2.5 2.3,5 22.9,5 22.9,2.5" fill={fill}/>
	        <path d="M4.7,21.8c0,1.6,1.3,2.9,2.9,2.9h9.8c1.6,0,2.9-1.3,2.9-2.9V6.1H4.7V21.8z M15.7,8.9h1.9v12.3h-1.9V8.9zM11.6,8.9h1.9v12.3h-1.9V8.9z M7.6,8.9h1.9v12.3H7.6V8.9z" fill={fill}/>
        </svg>
    );
};

export default DeleteIcon;
