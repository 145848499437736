const TimeImg = ({width, height }) => {
    return (
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 56 56" xmlSpace="preserve">
            <path fill="#F7F7F7" d="M46,0H10C4.5,0,0,4.5,0,10v36c0,5.5,4.5,10,10,10h36c5.5,0,10-4.5,10-10V10C56,4.5,51.5,0,46,0z M38.5,43.8
                H18.3c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h20.1c1,0,1.7,0.7,1.8,1.7C40.2,43.1,39.4,43.8,38.5,43.8z M19.4,18.6h18
                c0,4.6-2.8,8.6-6.5,9.8c3.8,1.2,6.5,5.1,6.5,9.8h-18c0-4.7,2.7-8.6,6.5-9.8C22.1,27.2,19.4,23.3,19.4,18.6z M38.5,16.4H18.3
                c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h20.1c1,0,1.7,0.7,1.8,1.7C40.2,15.7,39.4,16.4,38.5,16.4z"/>
        </svg>
    )
};

export default TimeImg;
