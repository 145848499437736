import style from '../row.module.scss';


const SubTitlesRow = ({ columns, theme }) => {
    return (
        <div className={style.main_row_content}>
            <p className={style.row} style={{textAlign:'center',backgroundColor:`${theme.background}`,color: `${theme.text}`}}>&#8226;</p>
            <p className={style.sub_tuition} style={{backgroundColor: `${theme.invertedbackground}`,color: `${theme.invertedtext}`}}>MATRICULA</p>
            <div className={style.sub_row}>
                
                <div className={style.sub_data}>
                    
                    {
                        columns.apellido
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>APELLIDO</p>
                            </div>
                        </div>
                    }

                    {
                        columns.nombre
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>NOMBRE</p>
                            </div>
                        </div>
                    }
                    
                    
                    {
                        columns.dni
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>DNI</p>
                            </div>
                        </div>
                    }
                  
                    {
                        columns.nacimiento
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>FECHA-NAC</p>
                            </div>
                        </div>
                    }

                    {
                        columns.genero
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>GÉNERO</p>
                            </div>
                        </div>
                    }

                    {
                        columns.mail
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>EMAIL</p>
                            </div>
                        </div>
                    }

                    {
                        columns.phone
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>TELÉFONO</p>
                            </div>
                        </div>
                    }

                    {
                        columns.personal
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>PERSONAL</p>
                            </div>
                        </div>
                    }

                    {
                        columns.jerarquia
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>JERARQUÍA</p>
                            </div>
                        </div>
                    }

                    {
                        columns.destino
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>DESTINO</p>
                            </div>
                        </div>
                    }

                    {
                        columns.zona
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>ZONA</p>
                            </div>
                        </div>
                    }

                    {
                        columns.descripcion
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content} style={{padding: '4px 6px',borderRadius: '4px',backgroundColor: `${theme.invertedbackground}`}}>
                                <p className={`${style.bold_text} ${style.item_content}`} style={{color:`${theme.invertedtext}`}}>DESCRIPCIÓN</p>
                            </div>
                        </div>
                    }

                </div>
                
              
            </div>
        </div>
    )
};

export default SubTitlesRow;