export const error_type = {
    AUTH: 'auth',
    SUBS: 'subscription',
    PHONEVERIF: 'phoneverify',
    PHONEEXISTS: 'phoneexists',
    CODEFAIL: 'codefail',
    CODENOTSENDED: 'codenotsended',
    CONTENTNOTCREATED: 'contentnotcreated',
    CONTENTNOTUPDATED: 'contentnotupdated',
    CONTENTNOTFOUND: 'contentnotfound',
    INVALIDCODE: 'invalidcode',
    USERBANNED: 'userbanned',
    PASSWORD: 'password',
    WRONGPASSWORD: 'wrongpassword',
    SAMEPASSWORD: 'samepassword',
    ANSWERED: 'answered',
    NOTOWNER: 'notowner',
    NOTFOUND: 'notfound',
    PUBNOTDELETABLE: 'pubnotdeletable',
    NOCONTENT: 'nocontent',
    SERVERERROR: 'servererror',
};