import { useState } from 'react';
import { saveToStorage, getFromStorage, deleteFromStorage } from '../assets';

const useSetGetDelete = (defaultState, key ) => {

    const [ state, setState ] = useState(() => {
        return getFromStorage(key) || defaultState;
    });
    
    const saveState = (data) => {
        setState(data);
        saveToStorage(key, data);
    };

    const deleteState = () => {
        setState(defaultState)
        deleteFromStorage(key);
    };

    return [ state, saveState, deleteState ];
};

export default useSetGetDelete;
