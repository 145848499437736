import style from './loader.module.scss';

const AltLoader = ( { width = 60, background } ) => {
    return (
        <div style={{margin: '30px'}}>
            <div className={style.loader} style={{width:`${width}px`,height:`${width}px`}}></div>
        </div>
    );
}

export default AltLoader;