const LogoAra = ({width="400px", opacity="100%"}) => {
    return (
        <svg viewBox="0 0 369.4 366.7" xmlSpace="preserve" width={width} opacity={opacity}>
            <g>
                <path d="M298.5,152.5l-57.6,0c-1.1,0-2.1-0.1-3-0.1c1-0.4,1.8-0.9,2.5-1.6c0.7-0.7,0.9-1.7,0.4-2.5
                    c-0.5-0.8-1.5-1.2-2.4-0.8c-3,1.2-7.9,1.2-12.7,1.3c-1.4,0-2.8,0-4.2,0c4.7-2.7,9.9-5.6,10.9-6.1c0.9-0.5,1.4-1.6,1-2.6
                    c-0.4-1-1.5-1.5-2.5-1.1c-1.5,0.5-8.4,2.6-13.8,4.2c1.6-1.1,3.3-2.1,5-3.2c5-3,9.2-5.7,10.5-9c0.3-0.9,0-1.9-0.8-2.4
                    c-0.8-0.5-1.9-0.4-2.5,0.3c-2.4,2.5-7.3,4.6-12.1,6.6c-1,0.4-2,0.8-3,1.3c1.7-2.1,3.4-4.3,4.6-5.8c2-2.5,3.1-3.9,3.4-4.3
                    c0.6-0.8,0.5-1.9-0.3-2.6c-0.7-0.7-1.8-0.7-2.6-0.1l-10.5,8.3c0.5-0.9,1.1-1.7,1.6-2.5c4.6-7.2,7.4-11.7,7.4-14.3
                    c0-1-0.7-1.8-1.6-2c-0.9-0.2-1.9,0.3-2.2,1.2c-1.3,3.1-4.9,6.8-8.3,10.4c-0.8,0.8-1.5,1.6-2.3,2.4l2.4-9.2c0.2-0.2,0.3-0.4,0.5-0.6
                    c0.1-0.1,0.2-0.3,0.2-0.5c0.7-0.4,1.3-1.1,1.8-1.9c0.8-0.4,1.7-1,2.4-2c1.2-0.4,2.2-1.2,3.1-2.7c1.1-0.5,2-1.3,2.7-2.8
                    c1.2-0.6,2.1-1.6,2.6-3c0.9-0.6,1.8-1.6,2.2-3.3c1-0.8,1.8-2,2-3.8c0.7-0.8,1.2-1.9,1.3-3.4c0.8-0.9,1.4-2.1,1.3-3.9
                    c0.7-0.9,1.1-2.1,0.9-3.7c0.7-1,1-2.4,0.6-4.2c0.1-0.2,0.2-0.4,0.2-0.7l2-0.2c0.3,0.3,0.8,0.4,1.2,0.4h5.1c0.8,0,1.6-0.5,1.9-1.3
                    l13.5-1.4c1-0.1,1.8-1,1.8-2v-9c0-0.5-0.2-1.1-0.6-1.4c-0.4-0.4-0.9-0.6-1.5-0.6l-13.2,0.4c-0.2-0.9-1-1.6-2-1.6h-5.1
                    c-1.1,0-1.9,0.8-2,1.9l-2.9,0.1c-0.1-0.1-0.1-0.2-0.2-0.4c0.1-1.1-0.2-2.4-1.3-3.7c0-1.2-0.5-2.5-1.7-3.8c-0.2-1.1-0.7-2.2-1.9-3.2
                    c-0.2-1.2-0.9-2.5-2.5-3.5c-0.4-1.1-1.1-2.1-2.6-2.9c-0.5-1.1-1.3-2.2-3-2.9c-0.3-0.5-0.7-1-1.3-1.5c0.9-2.7,1.4-5.5,1.4-8.4
                    c0-14.8-11.9-26.8-26.6-26.8c-14.7,0-26.6,12-26.6,26.8c0,10.7,6.8,20.7,16.8,24.8c0.3,0.1,0.6,0.2,0.8,0.1
                    c0.2,2.4,1.3,4.6,3.1,6.1l-6.7-0.2v-0.7c0-1.1-0.9-2-2-2h-4.9c-1.1,0-2,0.9-2,2v0.7l-21.2-0.8c-0.1-1-0.9-1.9-2-1.9h-5.1
                    c-1,0-1.8,0.7-2,1.6l-12.5-0.4c-0.5,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,0.9-0.6,1.4v8.6c0,1,0.7,1.8,1.7,2l12.7,1.7c0.1,0,0.2,0,0.2,0
                    c0.3,0.8,1,1.3,1.9,1.3h5.1c0.5,0,0.9-0.2,1.2-0.4c0.1,0,0.2,0,0.3,0.1l21.7,2.4c0,0,0.1,0,0.1,0c0.1,1,0.9,1.9,2,1.9h4.9
                    c0.7,0,1.3-0.3,1.6-0.8L179,86c0,0.1,0,0.2,0,0.3l-0.3,21.5c-0.3-0.6-0.7-1.1-1.1-1.6c-0.7-0.7-1.7-0.8-2.6-0.3
                    c-0.8,0.5-1.1,1.6-0.7,2.5c1.4,3.2,1.4,8.4,1.5,13.5c0,1,0,1.9,0,2.9l-6.1-11c-0.5-0.9-1.6-1.3-2.5-0.9c-0.9,0.4-1.4,1.4-1.2,2.4
                    l3.4,13.2c-0.8-1.2-1.5-2.4-2.3-3.6c-3-4.8-5.8-9.3-9.2-10.8c-0.9-0.4-1.9-0.1-2.5,0.7c-0.5,0.8-0.4,1.9,0.3,2.6
                    c2.5,2.4,4.5,7.1,6.4,11.7c0.4,0.9,0.8,1.8,1.2,2.8l-10.1-7.9c-0.8-0.6-1.9-0.5-2.7,0.2c-0.7,0.7-0.8,1.9-0.1,2.7l8.6,10.8
                    c-1.4-0.9-2.9-1.9-4.3-2.9c-4.7-3.2-8.8-6-12.3-6c-1,0-1.8,0.7-2,1.6s0.3,1.9,1.2,2.2c3.1,1.3,6.7,4.6,10.1,7.9
                    c0.8,0.8,1.6,1.5,2.4,2.3l-11.8-3.1c-1-0.3-2,0.3-2.4,1.2c-0.4,0.9,0,2,0.9,2.5l11.6,6.6c-1.5-0.3-3-0.6-4.5-0.9
                    c-5.6-1.2-10.8-2.4-14.3-0.8c-0.9,0.4-1.4,1.4-1.1,2.3c0.2,0.9,1.1,1.6,2.1,1.5c1.8-0.1,4,0.4,6.4,1.1h-19.9c0-0.1,0-0.2,0-0.3
                    c-0.2-5.9-1.8-11.8-4.7-17.5c-0.4-0.8-1.2-1.2-2.1-1c-0.2,0-0.4,0.1-0.5,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.4-1.1-0.6-1.7-0.5
                    l-3.1,0.6c0,0-0.1,0-0.1,0l-3.4,1c-0.3,0-0.5,0.1-0.8,0.2l-2.2,1c-0.4-0.2-0.8-0.4-1.2-0.3l-3.9,0.2c-0.3,0-0.7,0.1-0.9,0.3
                    l-2.7,1.6c-0.4,0.2-0.7,0.6-0.8,1l-0.1,0.3c-0.2-0.1-0.4-0.2-0.6-0.2c-2.1-0.3-3.7-0.2-5.5,0.2c-0.4,0.1-0.8,0.3-1.1,0.7l-1.1,1.4
                    c-0.2-0.1-0.4-0.2-0.6-0.2l-1.8-0.4c-0.8-0.2-1.6,0.1-2.1,0.8l-0.4,0.6c-2.9,4.2-4.4,6.3-10,10.6c-0.6,0.4-0.8,1.1-0.8,1.8l0.4,3.7
                    c0,0.2,0.1,0.3,0.1,0.5l3.1,8c0.1,0.3,0.3,0.5,0.5,0.7l0.8,0.7c3.2,3,6.5,6.2,11.3,8.6c0,0.1,0.1,0.1,0.1,0.2l-0.3,2.7
                    c-0.1,0.8,0.3,1.6,1.1,2l3.3,1.6c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.5-0.1,0.8-0.2l3.3-1.4c0.5-0.2,0.8-0.6,1-1l1.8-4.3
                    c0.4-1,0-2.2-1-2.6l-4.7-2.1l-1.2-1.3l-0.1-0.3c0.9,0.5,1.8,0.9,2.8,1.4c3.4,1.6,6.6,3.2,7.7,6.6c0.3,0.8,1,1.4,1.9,1.4
                    c1.2,0,2.1-0.4,2.6-1.2c0.2,0.1,0.4,0.1,0.6,0.1l5.7,0.4l0.4,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.8-0.2l1.1-0.5
                    c0.3,0.3,0.6,0.5,1,0.6c0.2,0,0.3,0.1,0.5,0.1c0.6,0,1.2-0.3,1.6-0.7c3.8-4.7,5.1-8.7,5.2-15.7h6.8c-0.2,0.3-0.3,0.6-0.4,1
                    c-1.1,0.6-2,1.6-2.5,3.3c-1,0.7-1.9,1.7-2.3,3.5c-0.9,0.8-1.6,2-1.9,3.8c-0.8,0.8-1.4,2-1.4,3.8c-0.8,0.9-1.3,2.2-1.1,4.1
                    c-0.7,1-1.1,2.3-1,4.1c0,0,0,0,0,0c-0.7,1.1-1,2.5-0.8,4.4c0,0.1,0.1,0.3,0.1,0.4c-0.3,1-0.3,2.1,0.2,3.4c-0.4,1.1-0.4,2.3,0.2,3.8
                    c-0.4,1.2-0.4,2.7,0.6,4.4c-0.2,1.1,0,2.3,0.8,3.5c-0.2,1.2,0.1,2.6,1.1,4.1c0,1.2,0.4,2.5,1.6,3.8c0.1,1.1,0.7,2.3,1.9,3.4
                    c0.2,1.2,0.9,2.3,2.3,3.3c0.3,1,1,2,2.2,2.8c0.3,1.4,1.1,2.7,3,3.6c0.4,1.2,1.3,2.3,3,3c0.4,1.2,1.3,2.4,3.1,3.1
                    c0.5,1,1.3,1.9,2.7,2.5c0.5,1.1,1.5,2,3.2,2.6c0.5,0.9,1.4,1.6,2.7,2.1c0.6,0.9,1.5,1.6,2.9,2.1c0.6,0.9,1.6,1.6,3.1,2.1
                    c0.6,0.7,1.4,1.4,2.7,1.8c0.6,0.9,1.6,1.6,3.1,2c0.6,0.8,1.5,1.4,2.9,1.8c0.6,0.8,1.6,1.5,3,1.9c0.7,0.8,1.7,1.5,3.3,1.8
                    c0,0,0,0,0,0c0.6,0.6,1.4,1.1,2.6,1.5c0.6,0.6,1.5,1.2,2.7,1.5c0.6,0.7,1.5,1.2,2.8,1.6c0.4,0.4,1,0.7,1.6,1l-0.3,20.6
                    c-4.3-1.8-8.4-3.3-12.3-4.7c-20.6-7.7-34.4-12.9-43.3-37.2l3.9,0.6c0.8,0.1,1.6-0.3,2-1c0.4-0.7,0.3-1.6-0.2-2.3
                    c-1-1.2-1.8-2.2-2.6-3.2c-4-5.6-7.7-11.7-10.9-18.2c-0.3-0.7-1-1.1-1.7-1.1c-0.7,0-1.4,0.3-1.8,1c-4.2,7.2-6.2,14-6.2,21.5
                    c0,2.3,0.4,4.4,0.8,6.7l0.1,0.6c0.1,0.8,0.7,1.4,1.5,1.6c0.8,0.2,1.6-0.1,2.1-0.7l2.6-3.1c10.2,30,27.8,38.4,49.8,49
                    c7.7,3.7,16.5,7.9,25.7,13.5c0.3,0.2,0.7,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.3c7.9-5.2,16.6-9.7,26.4-13.8c1.1-0.5,2.2-1,3.3-1.4
                    c0,0,0.1,0,0.1,0c0.7,1.4,1.9,2.4,3.7,2.9c0.4,0.1,0.8,0.1,1.1,0c0.3,0.1,0.6,0.1,0.9,0c0.1,0,0.3-0.1,0.4-0.1c1.8-0.7,3-2.3,3-4.3
                    c0-0.8-0.1-1.6-0.5-2.3c4-1.8,8.4-3.9,12.5-6.4c0,0,0.1,0,0.1-0.1c4.7-3.3,9.6-6.7,13.9-11.4c0.4-0.5,0.8-1,1.3-1.5
                    c1.7-2,3.4-4,4.8-6.3c3.2-4.5,5.6-9.5,7.6-13.9l1.6-4.1l3,3.5c0.5,0.6,1.4,0.9,2.2,0.6c0.8-0.3,1.3-1,1.3-1.9
                    c0-1.1,0.1-2.2,0.3-3.4c0.2-1.2,0.3-2.4,0.3-3.6c0-7.4-1.9-14.2-6-21.5c-0.4-0.6-1-1-1.8-1c-0.7,0-1.4,0.4-1.7,1.1
                    c-3.2,6.2-7.1,13.3-12.5,19.7c0,0-0.1,0.1-0.1,0.1l-0.7,1c-0.9,0.4-1.3,1.4-1,2.4c0.3,0.9,1.2,1.5,2.2,1.4l4.2-0.6
                    c-1,2.5-2,4.9-3,7.3c-2,3.2-4.3,6.6-7.2,10c-5.3,5-11.7,9-19.5,12.5c-4.6,2-9.4,3.7-14,5.4l-0.3,0.1c-0.7-1.6-2-2.2-3-2.3
                    c-0.5-1.2-1.4-2.3-3.3-2.5c-0.5-0.9-1.4-1.7-2.8-1.9c-0.5-1.2-1.4-2.1-3.1-2.3c-0.5-1-1.3-1.8-2.7-2.1c-0.5-1.3-1.5-2.1-2.9-2.4
                    l-0.8-47.5c0.1,0.3,0.3,0.5,0.4,0.6c0.6,0.7,1.7,0.9,2.6,0.4c0.8-0.5,1.2-1.5,0.8-2.5c-1.3-3.2-1.4-8.3-1.4-13.3
                    c0-1.3,0-2.5-0.1-3.8c1.4,2.5,3,5.5,5,9.1c0.5,1,0.9,1.7,1.1,2c0.5,0.9,1.6,1.3,2.5,0.9c0.9-0.4,1.4-1.4,1.1-2.4l-4-13.7
                    c0.9,1.2,1.8,2.5,2.5,3.6c4.6,6.7,7.6,10.8,10.2,11.7c0.9,0.3,1.9-0.1,2.4-0.9c0.5-0.8,0.3-1.8-0.4-2.5c-2.5-2.3-4.6-7.2-6.6-11.8
                    c-0.4-1-0.8-2-1.3-2.9c1.9,1.5,3.7,3,5.2,4.1c2.8,2.3,4.5,3.6,4.9,3.9c0.8,0.6,1.9,0.5,2.6-0.3c0.7-0.7,0.7-1.8,0.1-2.6l-8.9-11.2
                    c0.3,0.2,0.6,0.4,0.9,0.5c1.1,0.7,2.3,1.5,3.5,2.3c6.2,4.1,10.2,6.6,12.8,6.6c1,0,1.8-0.7,2-1.6c0.2-1-0.4-1.9-1.3-2.2
                    c-3.1-1.2-6.8-4.8-10.4-8.3c-0.9-0.8-1.7-1.7-2.6-2.5c5.2,1.4,11.1,3.1,12.3,3.5c1,0.3,2-0.1,2.5-1.1c0.4-0.9,0-2.1-0.9-2.6
                    l-11.7-6.5c1.2,0.2,2.4,0.5,3.5,0.7c7.9,1.5,12.8,2.3,15.4,1.2c0.9-0.4,1.3-1.3,1.1-2.3c-0.2-0.9-1-1.5-1.9-1.5c0,0,0,0-0.1,0
                    c-3.4,0.1-8-1.7-12.4-3.5c-1-0.4-1.9-0.8-2.9-1.1l5-0.6c0.4,0.4,0.9,0.6,1.4,0.6c0,0,0.1,0,0.1,0l10.4-0.4l58.1-0.2
                    c2.6,0,2.6-3.6,2.6-5.1S301.2,152.5,298.5,152.5z M196.7,272.3c0.6,0.9,1.5,1.7,2.9,2.2c0.6,0.9,1.5,1.7,3.1,2.2
                    c0.2,0.4,0.5,0.7,0.8,1c-1.9,0.7-3.7,1.5-5.5,2.2c-0.6,0.2-1.2,0.5-1.7,0.7l-0.2-8.7C196.3,272.2,196.5,272.2,196.7,272.3z
                    M175.8,248.6c-0.5-0.7-1.3-1.1-2.5-1.3c-0.6-0.8-1.4-1.4-2.8-1.6c-0.6-0.7-1.4-1.3-2.7-1.4c-0.6-0.9-1.5-1.5-2.9-1.7
                    c-0.5-0.9-1.4-1.5-2.9-1.7c-0.5-1-1.4-1.7-3-1.9c-0.5-0.9-1.3-1.6-2.6-1.8c-0.5-1.1-1.4-2-3-2.3c-0.5-0.8-1.3-1.5-2.6-1.7
                    c-0.5-1-1.4-1.9-2.9-2.1c-0.5-0.9-1.2-1.6-2.5-1.9c-0.4-1.1-1.2-2-2.8-2.3c-0.4-1.2-1.3-2.2-3-2.5c-0.4-1.1-1.3-2.1-2.8-2.4
                    c-0.3-1.2-1-2.4-2.5-2.9c-0.2-1.2-0.8-2.3-2-2.9c-0.1-1.3-0.6-2.5-1.9-3.3c-0.1-1.1-0.5-2.1-1.5-2.9c0-1-0.3-2-1.1-2.8
                    c0.2-1.1,0-2.2-0.9-3.2c0.2-1,0.1-2.1-0.5-3c0.4-1,0.4-2.2-0.4-3.5c0.4-1,0.6-2.2-0.1-3.4c0.6-1,0.8-2.2,0.2-3.4
                    c0.6-1,1-2.1,0.5-3.4c0.7-0.9,1.2-2,1-3.4c0.8-0.9,1.3-2,1.1-3.4c0.7-0.7,1.2-1.7,1.1-3c0.7-0.6,1.3-1.5,1.3-2.7c1-0.6,1.8-1.5,2-3
                    c0,0,0.1,0,0.1-0.1c0.6,0.5,1.4,0.7,2.2,0.4c3-1.3,8.2-1.4,13.1-1.4c1.2,0,2.4,0,3.6-0.1c-2.4,1.3-5.5,3.1-9.5,5.3
                    c-0.8,0.4-1.3,0.7-1.6,0.9c-0.9,0.5-1.3,1.6-0.9,2.5c0.4,0.9,1.4,1.4,2.4,1.2l13.6-4c-1.2,0.8-2.4,1.6-3.4,2.3
                    c-6.7,4.5-10.9,7.5-11.8,10.1c-0.3,0.9,0,1.9,0.8,2.4c0.8,0.5,1.9,0.3,2.5-0.4c0.9-0.9,7.5-4.4,15-8.2l-8.2,10.3
                    c-0.6,0.8-0.6,1.9,0.1,2.7c0.7,0.7,1.9,0.8,2.7,0.2l10.8-8.6c-0.7,1.1-1.4,2.1-2,3.1c-4.4,6.8-7.1,11.1-7.1,14c0,1,0.7,1.8,1.6,2
                    c0.9,0.2,1.9-0.3,2.2-1.2c1.3-3.1,4.6-6.7,7.9-10.1c0.7-0.8,1.5-1.6,2.2-2.4l-3,11.6c-0.3,1,0.2,2,1.2,2.4c0.9,0.4,2,0,2.5-0.9
                    l6.6-11.4c-0.3,1.4-0.6,2.9-0.9,4.3c-1.2,5.6-2.4,10.8-0.8,14.3c0.3,0.7,1.1,1.2,1.8,1.2c0.1,0,0.3,0,0.4,0l-0.5,34.5
                    C176.3,248.7,176.1,248.6,175.8,248.6z M198.7,85.3c0.4,0.5,1,0.8,1.6,0.8h4.9c1.1,0,1.9-0.8,2-1.9c0.1,0,0.2,0,0.3,0l11-1.2
                    c-0.3,0.8-0.5,1.7-0.2,2.8c-0.7,0.9-1.1,1.9-0.8,3.1c-0.8,0.8-1.3,1.9-1.1,3.2c-0.8,0.7-1.3,1.7-1.2,2.9c-0.9,0.7-1.5,1.6-1.5,2.8
                    c-0.9,0.6-1.6,1.4-1.7,2.6c-0.8,0.4-1.5,1.1-1.9,2.1c-1.2,0.4-2,1.3-2.5,2.5c-1,0.3-2,1-2.5,2.1c-1.1,0.2-2,0.7-2.7,1.8
                    c-1.3,0.1-2.5,0.8-3.2,2.3c-0.1,0-0.2,0-0.3,0.1c0.1-1.6-0.1-3.1-0.6-4.4c-0.4-0.9-1.3-1.4-2.2-1.2c-0.9,0.2-1.6,1-1.6,2
                    c0,2.3-0.8,5.1-1.7,7.8V86.3c0-0.1,0-0.2,0-0.3L198.7,85.3z M180.7,53.9c-0.2-0.3-0.5-0.5-0.8-0.6c-6.8-2.7-11.3-9.3-11.3-16.6
                    c0-9.4,8-17.3,17.5-17.3c9.5,0,17.5,7.9,17.5,17.3c0,1.6-0.2,3.1-0.6,4.6c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0
                    c-0.7-0.5-1.7-0.9-2.9-1.1c-0.4-0.1-0.8,0-1.1,0.2c-0.6-0.1-1.4,0-2.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.4c-1.9,1.7-2.7,4.3-1.9,6.4
                    c0.4,1,1.4,2.4,3.9,3c-1.4,1.2-3.1,2.2-4.8,2.9c-0.3,0.1-0.6,0.4-0.8,0.6c-1.5-1.1-3.5-1.8-5.6-1.8
                    C184.1,52.1,182.2,52.8,180.7,53.9z M139.9,163.2h2.4c0.4,0,0.8-0.1,1.1-0.4l7.6,0.9c-1.6,0.3-3.3,0.6-5,0.9
                    c-3.2,0.5-6.3,1.1-8.8,2c0,0,0,0,0,0C138.5,166.2,139.7,165.3,139.9,163.2z M195.2,62.3c0.2,0,0.3-0.1,0.5-0.1
                    c4.2-1.8,7.9-4.5,10.8-8c0.4,0.2,0.9,0.4,1.4,0.4c0.4,0.6,1.1,1.1,2,1.4c0.3,1,0.9,1.9,2,2.4c0.2,1.1,0.7,2.2,1.9,2.8
                    c0.1,1,0.5,1.9,1.4,2.5c0,1.2,0.3,2.4,1.4,3.2c0,0.2,0,0.4,0,0.6l-9.5,0.4v-0.7c0-1.1-0.9-2-2-2h-4.9c-1.1,0-2,0.9-2,2v0.7
                    l-6.1,0.2C193.9,66.7,195,64.6,195.2,62.3z" fill="#020304" />

                <path d="M190.6,193.2l2.3,9.2l1.4,81.2c3.7-1.4,7.6-3.3,11.7-4.7c7.4-2.9,15.2-5.3,22.8-8.6
                    c7.4-3.3,14.2-7.4,20.1-12.9c2.9-3.3,5.1-6.6,7.6-10.5c1.4-3.5,2.9-7,4.3-10.7l-7.6,1l0.4-0.2l1-1.4c5.1-6.2,9-12.9,12.7-20.1
                    c3.7,6.6,5.7,13.1,5.7,20.5c0,2.3-0.6,4.5-0.6,7l-5.1-5.9l-2.9,7.2c-2.1,4.5-4.3,9.2-7.4,13.5c-1.6,2.7-3.9,5.1-5.9,7.6
                    c-4.1,4.5-8.8,7.8-13.5,11.1c-7.8,4.7-16.4,8-24.4,11.5c-9.4,3.9-18.3,8.4-26.7,14c-36.1-21.7-62.6-21.7-75.7-64.8l-4.9,5.9
                    c-0.4-2.5-0.8-4.7-0.8-7c0-7.4,2.1-14,5.9-20.5c3.3,6.6,7,12.7,11.1,18.5c0.8,1,1.6,2.1,2.7,3.3l-7.2-1
                    c11.1,33.8,30.2,34.7,60.5,47.4l1-75.1c0.2-5.1,3.7-10.9,4.7-14.2l2.7,24L190.6,193.2z" fill="#C6C6C6"/>
                
                <path d="M88.7,181.5l-2.5-1.2l0.4-3.5c-0.4-0.2-1-2.5-1.2-1.4c-4.9-2.5-8.2-5.7-11.7-9l-3.1-8l-0.4-3.7
                    c6.2-4.7,7.6-7,10.9-11.7l1.8,0.4l0.4,16.4l1.4-16l1.8-2.3c1.6-0.4,3.1-0.4,4.7-0.2l-0.8,3.7l0.8,2.5v16.6l0.4,1v-1.4l0.6-9.2v-4.5
                    l0.4-6.2l1.4-3.9l2.7-1.6l3.9-0.2l-0.6,7.2l0.2,21.1l2.5,10.5c0,1.2-0.2,1.2-1,1.2c-1.8-5.9-8.4-7.2-12.5-9.8l-2.1-1.4l-1-1.4
                    l1.4,6.6l1.8,2.1l5.1,2.3l-1.8,4.3l-3.3,1.4L88.7,181.5z" fill="#E63027"/>
                <path d="M111,177.4l-5.9-0.4c-4.3-13.5-2.5-17.2-3.5-26.3l1-12.5l2.3-1c0.2,0,0.2,0,0.2,0l3.7-1l3.1-0.6
                    c-1,14.4-1.6,27.3,1.2,41.4l-1.4,0.6L111,177.4z" fill="#E63027"/>

                <path fill="#641100" d="M114.9,177.6c-1-4.7-1.6-9.8-1.6-14.6h6.4C119.6,169.8,118.4,173.3,114.9,177.6z"/>
                <path fill="#641100" d="M113.3,152.8c0.2-5.3,0.2-11.1,1-16.6c2.7,5.1,4.3,10.7,4.5,16.6H113.3z"/>
                <path d="M240.9,154.5h57.6c0.8,0,0.8,6.2,0,6.2l-58.1,0.2l-10.5,0.4l10.1-1.6l-21.5-3.3
                    C223.2,154.2,228.6,154,240.9,154.5z" fill="#988500"/>
                <polygon fill="#C6C6C6" points="181,86.3 185.7,87.2 190.6,86.3 190.6,120.8 189.4,125.3 186.1,103 183.2,127.8 180.6,112.8 	"/>
                <path d="M186.1,54.1c4.1,0,7.2,3.1,7.2,7.2c0,3.9-3.1,7-7.2,7c-3.9,0-7-3.1-7-7C179.1,57.2,182.2,54.1,186.1,54.1z" fill="#C6C6C6"/>
                <rect x="136.3" y="67.3" fill="#C6C6C6" width="5.1" height="12.9"/>
                <rect x="166.6" y="67.3" fill="#C6C6C6" width="4.9" height="16.8"/>
                <polygon fill="#988500" points="173.4,69.9 186.1,70.3 198.4,69.9 198.4,83.3 186.1,84.9 173.4,83.3 	"/>
                <rect x="200.3" y="67.3" fill="#C6C6C6" width="4.9" height="16.8"/>
                <polygon fill="#988500" points="207.2,69.9 229.2,69.1 229.2,79.8 207.2,82.2 	"/>
                <rect x="230.6" y="67.3" fill="#C6C6C6" width="5.1" height="12.9"/>
                <polygon fill="#988500" points="237.2,68.9 250.9,68.5 250.9,77.5 237.2,79 	"/>
                <path d="M177.1,60.1c-9-3.7-15.6-12.7-15.6-23c0-13.7,11.1-24.8,24.6-24.8c13.3,0,24.6,11.1,24.6,24.8
                    c0,10.3-6.6,19.3-15.8,23.2c-0.2-2.1-0.6-3.7-1.6-5.1c7.2-2.9,12.3-9.8,12.3-18.5c0-10.5-8.8-19.3-19.5-19.3
                    c-10.5,0-19.5,8.8-19.5,19.3c0,8.6,5.3,15.6,12.5,18.5C178.1,56.6,177.5,58.2,177.1,60.1z" fill="#C6C6C6"/>
                <path d="M217.1,103.6c-1,4.3-5.3,2.7-8,2.9C209.9,102.5,214,105.2,217.1,103.6z"/>
                <path d="M197.8,270.6c-4.3-1.2-2.5-5.3-2.7-8.2C198.8,262.8,196.4,268.5,197.8,270.6z"/>
                <path d="M197.8,270.6c-4.3-1.2-2.5-5.3-2.7-8.2C198.8,262.8,196.4,268.5,197.8,270.6z"/>
                <path d="M197.8,270.6c-4.3-1-2.5-5.3-2.7-8C198.8,262.8,196.4,268.7,197.8,270.6z"/>
                <polygon points="113.9,155.1 148.4,155.1 153.9,157.1 132.4,159.8 142.2,161.2 113.9,161.2" fill="#988500"/>
                <polygon points="143.3,69.1 143.3,79.9 164.8,82.2 164.8,69.9" fill="#988500"/>
                <polygon points="134.4,68.9 121.7,68.5 121.7,77.1 134.4,78.8" fill="#988500"/>
                <polygon points="143.1,69.1 143.1,79.8 164.8,82.2 164.8,69.9" fill="#988500"/>
                <g>
                    <path fill="#C1A158" d="M197.9,264.8c0.4,2.7-1.6,6.8,2.9,8C199.4,271,201.6,265,197.9,264.8z"/>
                    <path fill="#C1A158" d="M201,267c0.2,2.7-1.8,6.8,2.7,8C202.2,273.1,204.7,267.2,201,267z"/>
                    <path fill="#C1A158" d="M204,269.3c0.4,2.7-1.6,7,2.9,8C205.4,275.4,207.7,269.5,204,269.3z"/>
                    <path fill="#C1A158" d="M207,271.2c0.4,2.7-1.6,7,2.7,8.2C208.2,277.3,210.7,271.4,207,271.2z"/>
                    <path fill="#C1A158" d="M210.1,273.6c0.2,2.7-1.8,6.8,2.7,8C211.3,279.8,213.7,273.8,210.1,273.6z"/>
                    <path fill="#C1A158" d="M213.1,276.1c0.2,2.7-1.8,6.8,2.7,8C214.3,282.1,216.8,276.3,213.1,276.1z"/>
                    <path fill="#C1A158" d="M216,278.7c0.4,2.7-1.6,7,2.7,8C217.3,284.9,219.7,278.9,216,278.7z"/>
                    <path fill="#C1A158" d="M219,281.4c0.4,2.7-1.6,6.8,2.7,8C220.2,287.4,222.7,281.6,219,281.4z"/>
                    <path fill="#C1A158" d="M222,283.6c0,2.7-0.4,5.9,0.8,5.7C225.5,288.4,225.1,284.3,222,283.6z"/>
                    <path fill="#C1A158" d="M175.5,250.6c0.2,2.7-1.8,7,2.7,8C176.7,256.7,179.2,250.8,175.5,250.6z"/>
                    <path fill="#C1A158" d="M137.9,163c-2.7,0.1-6.7-2.1-8.1,2.2C131.7,163.8,137.6,166.7,137.9,163z"/>
                    <path fill="#C1A158" d="M123.8,198.1c-1.4,2.3-5.5,4.5-2.7,8C121.5,202.7,126.4,201.2,123.8,198.1z"/>
                    <path fill="#C1A158" d="M135.3,165.7c-2.7,0.3-6.9-1.7-7.9,2.7C129.3,167.1,135.2,169.2,135.3,165.7z"/>
                    <path fill="#C1A158" d="M123.5,194.7c-1.6,2.3-5.9,3.9-3.5,7.8C120.5,200,126.2,197.3,123.5,194.7z"/>
                    <path fill="#C1A158" d="M123.8,191.1c-1.6,2.1-6.2,3.3-4.1,7.4C120.1,196,126.2,194,123.8,191.1z"/>
                    <path fill="#C1A158" d="M132.9,221c0.2,2.7-1.6,6.8,2.7,8C134.1,227,136.6,221.3,132.9,221z"/>
                    <path fill="#C1A158" d="M136,224.1c0.2,2.7-1.8,6.8,2.7,8C137.2,230,139.6,224.3,136,224.1z"/>
                    <path fill="#C1A158" d="M139,226.4c0.3,2.7-2,6.9,2.2,8.1C139.9,232.7,142.7,226.9,139,226.4z"/>
                    <path fill="#C1A158" d="M141.9,228.9c0,2.7-2.3,6.8,2.1,8.2C142.7,235.1,145.5,229.3,141.9,228.9z"/>
                    <path fill="#C1A158" d="M147.4,233.1c0.2,2.7-2.1,6.8,2.1,8.2C148.2,239.3,151.1,233.6,147.4,233.1z"/>
                    <path fill="#C1A158" d="M155.7,239.2c0.2,2.7-1.8,7,2.7,8C156.9,245.3,159.4,239.4,155.7,239.2z"/>
                    <path fill="#C1A158" d="M144.5,231.2c0.1,2.7-1.8,6.7,2.4,8.1C145.6,237.2,148.2,231.5,144.5,231.2z"/>
                    <path fill="#C1A158" d="M152.9,236.9c0.1,2.7-1.9,6.9,2.4,8.3C153.9,243.1,156.6,237.2,152.9,236.9z"/>
                    <path fill="#C1A158" d="M150.3,235.2c0.1,2.7-2.2,6.7,2.2,8.1C151.2,241.2,153.9,235.6,150.3,235.2z"/>
                    <path fill="#C1A158" d="M158.6,241c0.2,2.7-1.8,6.8,2.7,8C159.9,247.1,162.3,241.2,158.6,241z"/>
                    <path fill="#C1A158" d="M161.6,242.9c0.2,2.7-1.8,6.8,2.7,8C162.8,249,165.3,243.1,161.6,242.9z"/>
                    <path fill="#C1A158" d="M164.5,244.6c0.2,2.7-1.8,7,2.7,8C165.8,250.7,168.2,244.8,164.5,244.6z"/>
                    <path fill="#C1A158" d="M167.4,246.2c0.4,2.7-1.6,6.8,2.9,8C168.8,252.4,171.1,246.5,167.4,246.2z"/>
                    <path fill="#C1A158" d="M170.1,247.7c0.2,2.7-1.6,7,2.7,8C171.3,253.8,173.8,247.9,170.1,247.7z"/>
                    <path fill="#C1A158" d="M172.8,249.2c0.4,2.7-1.6,6.8,2.7,8C174,255.2,176.5,249.4,172.8,249.2z"/>
                    <path fill="#C1A158" d="M124.3,187.5c-2.1,1.8-6.6,2.9-4.9,7C120.2,192.2,126.4,190.6,124.3,187.5z"/>
                    <path fill="#C1A158" d="M130.5,217.8c0,2.7-2.3,6.8,2.1,8.2C131.3,224,134.2,218.5,130.5,217.8z"/>
                    <path fill="#C1A158" d="M128.9,174.1c-2.6,1.1-7.1,0.2-6.9,4.8C123.3,177.1,129.6,177.8,128.9,174.1z"/>
                    <path fill="#C1A158" d="M127.5,177.2c-2.4,1.2-7.1,0.8-6.7,5.4C122.3,180.5,128.6,180.8,127.5,177.2z"/>
                    <path fill="#C1A158" d="M126.5,180.5c-2.4,1.3-6.9,1.5-6.6,6.1C121.2,184.4,127.7,184.1,126.5,180.5z"/>
                    <path fill="#C1A158" d="M125.4,183.9c-2.3,1.4-7,2.3-6.3,6.8C120.4,188.5,126.5,187.5,125.4,183.9z"/>
                    <path fill="#C1A158" d="M128.7,214.6c-0.2,2.7-2.9,6.4,1.2,8.2C128.9,220.7,132.4,215.4,128.7,214.6z"/>
                    <path fill="#C1A158" d="M130.9,171.3c-2.7,0.7-6.9-0.9-7.6,3.8C125,173.4,131.2,175,130.9,171.3z"/>
                    <path fill="#C1A158" d="M127,211.5c-0.4,2.7-3.5,6.2,0.4,8.4C126.6,217.6,130.5,212.7,127,211.5z"/>
                    <path fill="#C1A158" d="M132.9,168.4c-2.6,0.5-6.9-1-7.8,3.4C127,170.2,133.2,171.9,132.9,168.4z"/>
                    <path fill="#C1A158" d="M125.6,208.4c-0.6,2.5-3.9,5.7-0.2,8.4C124.6,214.6,129.1,209.8,125.6,208.4z"/>
                    <path fill="#C1A158" d="M124.8,205.1c-1,2.5-4.7,5.3-1.2,8.4C123.1,211.1,127.9,207,124.8,205.1z"/>
                    <path fill="#C1A158" d="M124,201.6c-1.2,2.5-4.9,4.9-1.8,8.4C121.9,207.6,127,203.9,124,201.6z"/>
                    <path fill="#C1A158" d="M196,116.5c2.8,0.6,6.1,3.7,8.6,0C202.3,117.2,197.5,113.1,196,116.5z"/>
                    <path fill="#C1A158" d="M201.8,118.8c-2.3,0.6-7-3.6-8.4-0.3C195.9,119.2,199.1,122.5,201.8,118.8z"/>
                    <path fill="#C1A158" d="M212.2,109.3c-2.3,1-7.6-2.3-8.4,1.4C206.4,110.8,210.3,113.4,212.2,109.3z"/>
                    <path fill="#C1A158" d="M209.3,111.8c-2.3,0.8-7.4-2.9-8.4,0.6C203.6,112.8,207,115.7,209.3,111.8z"/>
                    <path fill="#C1A158" d="M206.8,114c-2.3,1-7.4-2.9-8.6,0.6C201.1,115,204.6,117.9,206.8,114z"/>
                    <path fill="#C1A158" d="M214.8,106.4c-2.1,1.2-7.8-1.4-8.2,2.3C209.3,108.5,213.4,110.8,214.8,106.4z"/>
                    <path fill="#C1A158" d="M220.6,86.1c2.1-2.1,6.4-3.1,4.5-7.2C223.9,82,218.7,82.2,220.6,86.1z"/>
                    <path fill="#C1A158" d="M219.8,89.2c2.1-1.6,6.8-2.3,5.3-6.6C224.3,84.9,217.9,86.1,219.8,89.2z"/>
                    <path fill="#C1A158" d="M224.5,86.3c-1,2.3-7.4,2.9-5.9,6.2C220.8,91.1,225.5,90.9,224.5,86.3z"/>
                    <path fill="#C1A158" d="M223.7,90c-1.2,2.1-7.6,2.3-6.4,5.5C219.8,94.3,224.3,94.5,223.7,90z"/>
                    <path fill="#C1A158" d="M222.4,93.7c-1.4,1.8-7.6,1.4-6.8,4.9C218.1,97.6,222.6,98.2,222.4,93.7z"/>
                    <path fill="#C1A158" d="M221.2,97.2c-1.6,1.8-7.8,0.6-7.4,4.3C216.5,100.7,221,101.7,221.2,97.2z"/>
                    <path fill="#C1A158" d="M220.6,72.2c1-2.5,4.9-5.1,1.6-8.2C222.4,66.2,217.5,70.1,220.6,72.2z"/>
                    <path fill="#C1A158" d="M219.4,100.5c-1.8,1.6-7.8,0-7.6,3.7C214.4,103.6,218.7,105,219.4,100.5z"/>
                    <path fill="#C1A158" d="M221,75.7c1.2-2.5,5.3-4.7,2.5-8.2C223.5,69.9,218.1,73.4,221,75.7z"/>
                    <path fill="#C1A158" d="M217.3,103.6c-2.1,1.4-7.8-0.8-8,2.9C212,106,216.3,107.9,217.3,103.6z"/>
                    <path fill="#C1A158" d="M221.2,79c1.6-2.3,5.7-4.1,3.3-8C224.3,73.4,218.5,76.3,221.2,79z"/>
                    <path fill="#C1A158" d="M220.9,82.6c1.7-2,6.2-3.4,4-7.5C224.5,77.6,218.5,79.7,220.9,82.6z"/>
                    <path fill="#C1A158" d="M211.3,54.3c-0.4-2.7,1.4-7-2.9-8C210.1,49.4,207.2,53.5,211.3,54.3z"/>
                    <path fill="#C1A158" d="M208.5,52.6c-0.2-2.7,1.6-7-2.9-8C207,46.4,204.8,52.4,208.5,52.6z"/>
                    <path fill="#C1A158" d="M205.6,51.1c-0.3-2.7,1.3-7-3.3-7.8C203.8,45,201.9,51.1,205.6,51.1z"/>
                    <path fill="#C1A158" d="M202.7,49.9c-0.4-2.6,1-7.1-3.6-7.7C200.7,43.9,199,50,202.7,49.9z"/>
                    <path fill="#C1A158" d="M199.9,48.7c-0.8-2.6,0.1-7.5-3.2-6.2C194.5,44.5,193.9,48.7,199.9,48.7z"/>
                    <path fill="#C1A158" d="M213.6,56.8c-0.2-2.7,2.1-6.8-2.3-8.2C212.6,50.6,209.9,56.4,213.6,56.8z"/>
                    <path fill="#C1A158" d="M215.5,59.7c0.2-2.7,2.9-6.6-1.4-8.4C215.2,53.5,212,58.9,215.5,59.7z"/>
                    <path fill="#C1A158" d="M217.3,62.5c0.4-2.7,3.3-6.2-0.6-8.4C217.5,56.4,213.8,61.5,217.3,62.5z"/>
                    <path fill="#C1A158" d="M218.6,65.8c0.6-2.7,3.9-5.9,0.2-8.4C219.4,59.6,215.1,64.4,218.6,65.8z"/>
                    <path fill="#C1A158" d="M219.9,68.9c0.8-2.5,4.3-5.5,0.8-8.4C221.1,63,216.6,67.3,219.9,68.9z"/>
                </g>
                <path fill="#FFD500" d="M235.4,159.8l-22.2-2.9c7-4.3,21.5-3.7,25.8-7.6c-6.6,2.7-19.5,0.2-26.9,2.7c0-0.4,17.6-10.3,19.3-11.1
                    c-1.8,0.6-21.1,6.6-21.5,5.9c4.7-6.6,18.7-11.3,20.7-16.6c-4.9,5.1-17.4,8-23.4,12.5c-0.4-0.4,12.3-15.8,13.3-17.2l-17.2,13.7
                    c-1.8-1.2,12.5-19.5,12.5-23.8c-2.7,6.6-13.3,14.2-16.8,20.7l5.7-22l-10.9,19.9c-1.6-7.8,4.7-20.9,2.5-26.5
                    c0,7.2-6.6,17.8-7.2,25.6l-2.9-22.8l-2.7,22.8c-4.3-6.8-3.5-21.3-7.6-25.6c2.9,6.6,0.6,19.3,2.7,26.7l-10.9-19.5l5.5,21.5
                    c-6.2-4.5-10.9-18.1-16.4-20.5c5.1,4.9,7.8,17.4,12.5,23.4l-17.6-13.7l14,17.4c-8-1.8-17.8-12.3-23.6-12.3
                    c6.6,2.7,13.7,12.7,20.7,16.4l-21.3-5.5l19.1,10.9c-8,1.2-20.9-4.9-26.5-2.5c7.2-0.4,17.8,6.6,25.4,7.4l-21.5,2.9l21.5,2.7
                    c-6.8,4.3-21.1,3.5-25.2,7.6c6.4-2.7,19.1-0.6,26.3-2.7c0.4,0.4-17,9.8-18.9,10.9l21.1-6.2c1.4,2.7-18.7,12.7-20.3,17.2
                    c1.8-2.1,23.6-12.5,23.6-12.5l-13.7,17.2l17-13.5c2.1,1.8-12.1,18.7-12.1,23.6c2.7-6.6,12.7-13.7,16.4-20.7l-5.5,21.1l10.9-18.9
                    c1.2,7.8-4.9,20.7-2.5,26.3c-0.4-7,6.6-17.8,7.4-25.4c0.2,0,2.5,20.1,2.9,21.7l2.5-21.7c1-1.2,5.9,23.6,7.6,25.4
                    c-2.7-6.6-0.6-19.1-2.7-26.7c0.6,0,9.8,17.2,10.9,19.1l-6.2-21.1c2.7-1.2,13.1,18.9,17.4,20.3c-5.1-4.7-8-17.2-12.7-23.4
                    c0.4-0.2,16,12.5,17.4,13.5l-13.7-17.2c0.6-0.8,4.3,1.2,8.4,3.7c5.7,3.7,12.5,8.6,15.2,8.6c-6.6-2.5-14-13.1-20.7-16.8
                    c0-0.4,19.5,5.1,21.3,5.7l-19.2-10.6c-0.1,0.1-0.2,0.2-0.3,0.4l0.2-0.4l0.1,0c2.7-2,22.3,4.4,26.6,2.4c-7.2,0.2-17.8-6.8-25.8-7.4
                    L235.4,159.8z M195.1,149.7c4.7,0,4.5,1.2,5.5,2.3c-4.7-3.1-7.8,0.8-10.1-1C192.3,150.3,192.9,149.9,195.1,149.7z M186.7,164.9
                    c0-0.2,2.9-0.2,4.1-1l-0.2-1.4l-1.4-0.8l-0.6-3.1h0.6l0.6,2.5l2.3,0.6l0.4,2.3l-1.8,1.4l-2.3,0.4l-1.6,0.4l-1.8-0.4l-2.5-0.4
                    l-1.8-1.4l0.4-2.3l2.7-0.6l0.6-2.5h0.4l-0.4,3.1l-1.6,0.8l-0.2,1.4C183.7,164.7,186.7,164.5,186.7,164.9z M178.1,149.7
                    c2.3,0.2,3.1,0.6,4.7,1.2c-2.5,1.8-5.5-2.1-10.1,1C173.8,151,173.6,149.7,178.1,149.7z M178.7,158.1c-2.7,0-4.9-0.8-6.8-2.1
                    c1.6,0.4,2.9,0.4,4.3,0.4c-0.6-0.6-1.2-2.1-0.2-3.1l-2.9,0.6c3.5-2.3,7-3.3,10.9,0h-2.7c0.4,1,0.4,2.1-0.6,2.5l4.1-0.4
                    C182.8,157.1,181,158.4,178.7,158.1z M187.3,170.2c-2.7,1-3.7-0.6-7.2-0.8c3.5-0.6,4.7-0.6,7.4-0.2c2.9-0.4,4.1-0.4,7.2,0
                    C191.2,169.6,189.8,171.1,187.3,170.2z M194.7,158.1c-2.5,0.2-4.1-1-5.9-2.1l3.7,0.4c-1.2-0.4-1.2-1.4-0.6-2.5h-2.5
                    c3.9-3.3,7.2-2.3,10.9,0l-3.1-0.6c0.8,1,0.8,2.5-0.4,3.1c1.6,0,3.1,0,4.5-0.4C199.5,157.3,197.2,158.1,194.7,158.1z"/>
            </g>
            <g>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M64.1,315.6C64.1,315.6,64.1,315.6,64.1,315.6C64.1,315.6,64.1,315.6,64.1,315.6z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M63.4,316.2l6.8-20c-1.6-0.2-3.4-0.7-5.2-1.7c-5.7-3.1-13.8-0.4-16.8,8.6c5.4-4.1,11.5,0.8,10.3,5c-1.8,6.2-8.8,5.7-13.5,12.4c-3.9,5.6-2.1,13.7-2.1,13.7s5.6-10,12.8-5.2c7.2,4.8,22.9,26.1,27.1,16.1c0.6-1.5,0.8-3.2,0.7-5C80.8,326.5,61,324.4,63.4,316.2z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M64.3,315.5C64.3,315.5,64.3,315.5,64.3,315.5C64.3,315.5,64.3,315.5,64.3,315.5z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M63.9,315.7C63.9,315.7,63.9,315.7,63.9,315.7C63.9,315.7,63.9,315.7,63.9,315.7z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M63.6,316.1C63.6,316.1,63.6,316.1,63.6,316.1C63.6,316.1,63.6,316.1,63.6,316.1z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M63.5,316.2C63.5,316.2,63.5,316.2,63.5,316.2C63.5,316.2,63.5,316.2,63.5,316.2z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M63.5,316.1C63.5,316.1,63.5,316.1,63.5,316.1C63.5,316.1,63.5,316.1,63.5,316.1z"/>
            </g>
            <path fill="#BABABA" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M76.4,317.9c-9.4-6.8-13-1.7-13-1.7c-2.4,8.2,17.5,10.3,20.2,24.1c-0.1-6.7,1.6-11.7,3.3-15.2C82,321.9,78.4,319.4,76.4,317.9z"/>
            <g>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M305.3,315.6C305.3,315.6,305.3,315.6,305.3,315.6C305.3,315.6,305.3,315.6,305.3,315.6z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M305.9,316.2l-6.8-20c1.6-0.2,3.4-0.7,5.2-1.7c5.7-3.1,13.8-0.4,16.8,8.6c-5.4-4.1-11.5,0.8-10.3,5c1.8,6.2,8.8,5.7,13.5,12.4c3.9,5.6,2.1,13.7,2.1,13.7s-5.6-10-12.8-5.2c-7.2,4.8-22.9,26.1-27.1,16.1c-0.6-1.5-0.8-3.2-0.7-5C288.6,326.5,308.4,324.4,305.9,316.2z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M305.1,315.5C305.1,315.5,305.1,315.5,305.1,315.5C305.1,315.5,305.1,315.5,305.1,315.5z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M305.4,315.7C305.4,315.7,305.4,315.7,305.4,315.7C305.4,315.7,305.4,315.7,305.4,315.7z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M305.8,316.1C305.8,316.1,305.8,316.1,305.8,316.1C305.8,316.1,305.8,316.1,305.8,316.1z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M305.9,316.2C305.9,316.2,305.9,316.2,305.9,316.2C305.9,316.2,305.9,316.2,305.9,316.2z"/>
                <path fill="#E2E2E1" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M305.9,316.1C305.8,316.1,305.8,316.1,305.9,316.1C305.8,316.1,305.8,316.1,305.9,316.1z"/>
            </g>
            <path fill="#FFFFFF" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M297.3,290.8c0,0-2.6-6.8-6.6-6.7s-10.7,6.2-42.7,23.3c-32,17.1-63.3,16.8-63.3,16.8s-31.3,0.4-63.3-16.8c-32-17.1-38.8-23.2-42.7-23.3c-4-0.1-6.6,6.7-6.6,6.7l-8.7,25.4c0,0,3.6-5.1,13,1.7c9.4,6.8,55,39.3,108.3,39.3c53.2,0,98.9-32.6,108.3-39.3c9.4-6.8,13-1.7,13-1.7L297.3,290.8z"/>
            <path fill="#BABABA" stroke="#010202" strokeWidth="0.5" strokeLinejoin="round" d="M292.9,317.9c9.4-6.8,13-1.7,13-1.7c2.4,8.2-17.5,10.3-20.2,24.1c0.1-6.7-1.6-11.7-3.3-15.2C287.4,321.9,291,319.4,292.9,317.9z"/>
            <g>
                <path fill="#CFCCCC" stroke="#010202" strokeWidth="0.5" d="M319.6,52.8c0.4-3.3,1.4-4.7,1.4-4.7l2.4,0.3c9.2,1.5,7,5.7,7,5.7S328.8,58.3,319.6,52.8z"/>
                <path fill="#CFCCCC" stroke="#010202" strokeWidth="0.5" d="M329.4,56.8c0,0.1-0.1,0.2-0.1,0.3C329.4,56.9,329.4,56.8,329.4,56.8z"/>
                <path fill="#CFCCCC" stroke="#010202" strokeWidth="0.5" d="M330.3,54.1c0,0,0,0.1,0,0.2C330.3,54.2,330.3,54.1,330.3,54.1z"/>
            </g>
            <g>
                <path fill="#EAEAEA" stroke="#010202" strokeWidth="0.5" d="M322.4,77.4c1.1-2.2,7.9-23.3,7.9-23.3s2.6-4.9-9.3-6c16.9,0,31.8,6.5,41.8,21.6c-10.4-3.4-20.5-3.2-28.5,0.8c5.8,3.7,12.7,12.5,13.3,23c-7.4-8.1-16.3-12.4-26.6-13.3C321.6,79.1,322.1,78,322.4,77.4z"/>
            </g>
            <path fill="#FFFFFF" stroke="#010202" strokeWidth="0.5" d="M215.4,11.7c0,0,23.6,8.5,15.2,34.9c27.9,5.8,59.4,20.7,78.2,34.9c9,6.8,12.4-1.8,13.6-4c1.1-2.2,7.9-23.3,7.9-23.3s-2.2,6.2-17.7-6.3C298.1,36.2,255.6,13.4,215.4,11.7z"/>
            <g>
                <path fill="#CFCCCC" stroke="#010202" strokeWidth="0.5" d="M54.6,52.8c-0.4-3.3-1.4-4.7-1.4-4.7l-2.4,0.3c-9.2,1.5-7,5.7-7,5.7S45.4,58.3,54.6,52.8z"/>
                <path fill="#CFCCCC" stroke="#010202" strokeWidth="0.5" d="M44.8,56.8c0,0.1,0.1,0.2,0.1,0.3C44.8,56.9,44.8,56.8,44.8,56.8z"/>
                <path fill="#CFCCCC" stroke="#010202" strokeWidth="0.5" d="M43.9,54.1c0,0,0,0.1,0,0.2C43.9,54.2,43.9,54.1,43.9,54.1z"/>
            </g>
            <g>
                <path fill="#EAEAEA" stroke="#010202" strokeWidth="0.5" d="M51.8,77.4c-1.1-2.2-7.9-23.3-7.9-23.3s-2.6-4.9,9.3-6c-16.9,0-31.8,6.5-41.8,21.6c10.4-3.4,20.5-3.2,28.5,0.8c-5.8,3.7-12.7,12.5-13.3,23c7.4-8.1,16.3-12.4,26.6-13.3C52.6,79.1,52.1,78,51.8,77.4z"/>
            </g>
            <path fill="#FFFFFF" stroke="#010202" strokeWidth="0.5" d="M158.8,11.7c0,0-23.6,8.5-15.2,34.9c-27.9,5.8-59.4,20.7-78.2,34.9c-9,6.8-12.4-1.8-13.6-4c-1.1-2.2-7.9-23.3-7.9-23.3s2.2,6.2,17.7-6.3C76,36.2,118.6,13.4,158.8,11.7z"/>
            <g>
                <path d="M99.7,44c0.1-0.3,0.2-0.5,0.3-0.7c0.5-1.4,0.1-2.7-0.8-3.8c-0.9-1-2.1-1.3-3.4-0.8c-1.5,0.6-2.9,1.2-4.4,1.9
                    c1.8,4.3,3.6,8.4,5.5,12.6c1.3-0.6,2.5-1.1,3.6-1.6c0.4-0.2,0.7-0.3,1.1-0.6c1.7-1.1,2.2-2.5,1.5-4.3
                    C102.5,45.3,101.5,44.3,99.7,44z M93.7,41.4c1.2-0.4,2.3-1.6,3.5-0.7c0.6,0.4,1.2,1.5,1.1,2.3c-0.1,1.6-1.7,1.7-3,2.3
                    C94.8,43.9,94.3,42.7,93.7,41.4z M101.2,48.6c-0.3,1.8-2.1,1.7-3.3,2.5c-0.7-1.5-1.3-2.9-1.9-4.5c1.4-0.6,2.6-1.6,4.1-0.7
                    C101,46.5,101.4,47.5,101.2,48.6z"/>
                <path d="M68.3,65.5c-0.8-1-1.6-2-3.2-1.8c0.1-0.3,0.1-0.6,0.2-0.8c0.4-2.7-2.5-5.3-4.9-4.2c-1.7,0.8-3.1,2.1-4.7,3.2
                    c2.8,3.8,5.5,7.4,8.3,11.1c0.5-0.4,1-0.8,1.5-1.2c-1.2-1.7-2.4-3.2-3.6-4.8c0.5-0.4,0.9-0.7,1.4-1c1.5-1.1,1.9-1,3.2,0.4
                    c0.6,0.7,1.3,1.4,1.9,2.2c0.4,0.5,0.9,0.6,1.5,0.1c0.3-0.3,0.7-0.5,0.9-0.7C69.8,67.1,69,66.4,68.3,65.5z M60.9,65.8
                    c-0.9-1.2-1.8-2.4-2.8-3.7c1.3-0.7,2.2-2.2,3.7-1.5c0.7,0.4,1.4,1.4,1.5,2.2C63.6,64.4,62,64.9,60.9,65.8z"/>
                <path d="M129.6,28.1c-0.4-0.7-0.9-0.5-1.4-0.3c-0.6,0.2-1.2,0.1-1.2,1.1c0,3.2-0.2,6.4-0.2,9.6c0,1.3,0,2.6,0,4
                    c0.7-0.2,1.3-0.3,2-0.5c0-1.3-0.5-2.9,0.1-3.6c0.7-0.8,2.3-0.8,3.5-1.2c0.1,0,0.2,0,0.3,0c0.3,0.6,0.7,1.2,1,1.8
                    c0.9,1.5,0.9,1.5,2.7,0.8C134,35.8,131.8,32,129.6,28.1z M128.6,36.4c0-2.1,0-4,0-5.8c0.1,0,0.1-0.1,0.2-0.1c1,1.7,1.9,3.3,2.9,5.1
                    C130.7,35.9,129.7,36.1,128.6,36.4z"/>
                <path d="M92.1,48.7c-1.1-2.3-2.2-4.5-3.3-6.8c-0.6,0.3-1.2,0.6-1.7,0.8c0.5,1,0.9,1.9,1.3,2.7c1.1,2.1,2.2,4.3,3.1,6.4
                    c0.7,1.5,0.3,2.4-1,3c-1.2,0.6-2.1,0.3-2.9-1c-0.3-0.4-0.5-0.8-0.7-1.3c-1.3-2.6-2.6-5.2-3.9-7.9c-0.6,0.3-1.1,0.6-1.7,0.8
                    c1.4,2.9,2.8,5.7,4.2,8.4c0.3,0.5,0.6,1.1,0.9,1.5c1.2,1.5,2.7,1.8,4.6,0.9c1.9-0.9,2.8-2.4,2.4-4.2C93.2,51,92.6,49.8,92.1,48.7z"
                    />
                <path d="M72.6,64.7c-0.9-1.4-1.7-2.6-2.5-3.9c1.4-0.9,2.8-1.8,4.2-2.8c-0.4-0.5-0.6-0.9-0.9-1.4c-1.4,0.9-2.8,1.8-4.2,2.7
                    c-0.7-1.2-1.4-2.3-2.2-3.4c1.5-1,3-1.9,4.5-2.9c-0.3-0.5-0.6-0.9-0.9-1.4c-2,1.3-4,2.5-6.1,3.9c2.5,3.9,5,7.7,7.4,11.6
                    c2.1-1.4,4.2-2.7,6.3-4c-0.4-0.5-0.6-1-0.9-1.4C75.7,62.8,74.2,63.7,72.6,64.7z"/>
                <path d="M123.1,38.3c0.1,0.7,0.1,1.4,0.2,2.1c0.1,1-0.4,1.6-1.3,1.9c-1,0.4-1.8,0.2-2.4-0.6c-1.7-2.4-2.5-5.1-2.6-8.1
                    c0-1,0.7-1.7,1.7-1.9c1-0.3,1.7,0.2,2.2,1c0.2,0.3,0.4,0.7,0.6,1.1c0.6-0.2,1.2-0.4,1.7-0.5c-0.6-2.4-1.9-3.4-4-3.3
                    c-2.2,0.1-3.8,1.4-3.9,3.6c-0.2,3.5,0.8,6.7,3,9.4c0.8,1,2.1,1.3,3.4,1c3-0.6,4.2-3.1,3-6.3C124.2,37.9,123.7,38.1,123.1,38.3z"/>
                <path d="M81.9,50.3c-0.9-2.1-2.5-3.1-4.3-2.4c-1.6,0.6-3.1,1.5-4.7,2.4c2.3,4.1,4.6,8.1,6.8,12.1c0.6-0.3,1-0.6,1.6-0.9
                    c-1-1.7-1.9-3.3-2.8-5c0.8-0.5,1.5-0.8,2.1-1.2C82.3,53.9,82.8,52.2,81.9,50.3z M77.7,54.9c-0.8-1.4-1.5-2.7-2.4-4.2
                    c1.2-0.5,2.1-1.7,3.5-1.1c1.1,0.5,1.6,1.5,1.6,2.6C80.4,53.9,78.9,54.2,77.7,54.9z"/>
                <path d="M107.3,47c-1.5-3.8-2.9-7.5-4.3-11.2c-0.6,0.2-1.1,0.4-1.7,0.7c1.6,4.3,3.2,8.5,4.9,12.8c2.3-0.9,4.6-1.8,6.9-2.6
                    c-0.2-0.6-0.4-1-0.6-1.6C110.8,45.8,109.1,46.4,107.3,47z"/>
                <path d="M109.8,33.3c1.5,4.4,3,8.6,4.5,13c0.6-0.2,1.1-0.4,1.8-0.6c-1.5-4.3-3-8.6-4.5-13C111,32.9,110.4,33.1,109.8,33.3z"/>
                <path d="M85.7,39.7c-2.1,0.6-2.3,1.1-1.7,3.9C85.8,42.8,85.1,41,85.7,39.7z"/>
            </g>
            <g>
                <path d="M310.3,55.5c-1.9,2.8-3.8,5.6-5.8,8.7c0-0.6,0-0.8,0-1c0.6-2.7,1.1-5.4,1.7-8.1c0.5-2.1,0.5-2.1-1.5-3.3
                    c-2.5,3.8-5.1,7.6-7.6,11.4c0.6,0.4,1,0.7,1.6,1c1.9-2.8,3.7-5.6,5.6-8.3c-0.4,2.9-1,5.7-1.6,8.5c-0.5,2.2-0.5,2.2,1.4,3.4
                    c0.1,0,0.2,0,0.3,0c2.5-3.7,5-7.5,7.5-11.3C311.3,56.1,310.8,55.8,310.3,55.5z"/>
                <path d="M288.1,42.5c-1.5,3.1-3,6.2-4.5,9.2c0-3.1,0.2-6,0.4-9c0.2-2.2,0.2-2.3-2.1-3c-1.9,4.1-3.9,8.1-5.9,12.3
                    c0.6,0.3,1.1,0.5,1.7,0.8c1.5-3.1,2.9-6,4.3-9c0.1,0,0.1,0,0.2,0.1c-0.2,3-0.5,6-0.6,9.1c-0.1,1.9,0.1,2.2,2.2,2.6
                    c1.9-4.1,3.9-8.1,5.9-12.3C289.1,43,288.6,42.8,288.1,42.5z"/>
                <path d="M259.4,32.6c-0.1-1.7-1.2-2.4-2.6-2.9c-1.3-0.4-2.6-0.9-4.1-1.4c-1.5,4.4-2.9,8.7-4.3,13c0.6,0.2,1.2,0.4,1.8,0.6
                    c0.7-1.9,1.3-3.8,1.9-5.7c0.7,0.2,1.4,0.4,2,0.6c1.3,0.5,1.6,1.1,1.2,2.4c-0.2,0.7-0.4,1.4-0.6,2.1c-0.5,1.9-0.3,2.3,1.6,2.5
                    c0.3-1.2,0.5-2.5,0.8-3.7c0.3-1.2,0.4-2.2-0.4-3.3c0.2-0.1,0.3-0.2,0.5-0.2C258.7,36,259.5,34.4,259.4,32.6z M256.4,35
                    c-1.2,1.1-2.5,0.1-3.9-0.3c0.5-1.5,0.9-2.8,1.4-4.3c1.4,0.5,3.1,0.5,3.4,2.2C257.5,33.4,257,34.5,256.4,35z"/>
                <path d="M264.2,39.4c-0.2,0.5-0.3,0.9-0.5,1.4c0.4,0.2,0.8,0.4,1.1,0.5c0.4,0.2,0.8,0.3,1.4,0.5c-0.4,0.8-0.7,1.5-1.2,2.2
                    c-0.6,0.9-1.4,1.5-2.6,1c-1.1-0.4-1.7-1.4-1.3-2.6c0.6-2.1,1.5-4.2,2.3-6.3c0.2-0.4,0.5-0.8,0.9-1.1c0.7-0.6,1.5-0.7,2.3-0.3
                    c0.8,0.4,1.1,1.1,1.1,1.9c0,1.5,0,1.6,1.7,1.7c0.1-0.7,0.2-1.3,0.2-2c-0.1-1.8-1.3-2.8-2.8-3.3c-1.7-0.6-3.2-0.2-4.3,1.1
                    c-2,2.5-3,5.3-3.2,8.5c-0.1,1.2,0.2,2.3,1.2,3.1c1.3,1,2.7,1.1,4.3,0.4c-0.1,0.5-0.1,0.8-0.2,1.1c0.4,0.2,0.8,0.3,1.3,0.4
                    c0.8-2.3,1.7-4.5,2.5-6.8C266.8,40.4,265.5,39.9,264.2,39.4z"/>
                <path d="M316.6,60c-3.9,2.8-7.7,5.5-11.5,8.2c1.3,1.4,1.3,1.4,2.7,0.4c0.6-0.4,1.2-0.9,1.9-1.3c1.1,0.9,2.2,1.7,3.2,2.6
                    c-0.3,0.8-0.6,1.5-0.9,2.2c-0.7,1.5-0.7,1.5,1,2.4c0.1-0.3,0.3-0.6,0.4-0.9c1.5-3.4,2.9-6.7,4.4-10.1
                    C318.6,61.3,318.6,61.3,316.6,60z M313.6,68.3c-0.9-0.7-1.7-1.3-2.6-2.1c1.6-1.2,3.2-2.3,4.7-3.4c0.1,0,0.2,0.1,0.2,0.1
                    C315.1,64.7,314.4,66.4,313.6,68.3z"/>
                <path d="M237.9,38.2c1.7,0.8,1.7,0.8,2.3-0.1c1.6-2.5,1.6-2.5,4.6-1.6c0.1,0,0.2,0,0.2,0.1c0.8,0.1,0.8,0.6,0.8,1.2
                    c-0.1,0.9-0.1,1.8-0.1,2.8c0.5,0.2,1.1,0.3,1.7,0.5c0.1-4.9,0.2-9.5,0.4-14.2c-0.8-0.2-1.5-0.4-2.2-0.6
                    C242.9,30.3,240.4,34.3,237.9,38.2z M245.9,29.4c0,1.9-0.1,3.7-0.1,5.7c-1.1-0.3-2-0.6-3.1-0.9c1.1-1.7,2.1-3.3,3.1-4.9
                    C245.8,29.3,245.8,29.4,245.9,29.4z"/>
                <path d="M272,43.4c1.6,0.7,3.1,1.3,4.6,1.9c0.2-0.5,0.4-1,0.6-1.5c-1.5-0.7-3-1.3-4.6-2c0.6-1.3,1-2.4,1.6-3.7
                    c1.7,0.7,3.2,1.4,4.9,2.1c0.2-0.5,0.4-1,0.6-1.6c-2.2-0.9-4.3-1.8-6.6-2.8c-1.8,4.3-3.6,8.4-5.4,12.7c2.3,1,4.6,1.9,6.8,2.9
                    c0.3-0.6,0.5-1,0.7-1.5c-1.7-0.8-3.4-1.5-5.1-2.2C270.8,46.1,271.4,44.8,272,43.4z"/>
                <path d="M298.4,49.7c0.3-0.5,0.5-1,0.8-1.5c-2.6-1.4-5.1-2.7-7.6-4.1c-0.3,0.6-0.5,1-0.8,1.5c1,0.6,1.9,1.1,2.9,1.7
                    c-1.9,3.6-3.8,7.1-5.7,10.6c0.6,0.3,1.1,0.6,1.6,0.9c1.9-3.6,3.8-7,5.8-10.6C296.4,48.7,297.3,49.2,298.4,49.7z"/>
                <path d="M300.6,49.4c-2.4,4-4.7,7.9-7,11.8c0.6,0.4,1.1,0.6,1.6,1c2.4-4,4.7-7.8,7-11.8C301.6,50,301.1,49.7,300.6,49.4z"/>
            </g>
            <g>
                <path fill="#010202" d="M156.1,326.9c-1-0.3-2-0.6-3-0.9c-2.5-0.7-4.9-1.3-7.5-2c-1.8,6.6-3.5,13.1-5.2,19.6c1.3,0.3,2.3,0.6,3.5,0.9
                    c0.7-2.7,1.4-5.3,2.2-8c1.7,0.4,3.2,0.8,4.7,1.3c2.8,0.8,3.6,2,3,4.8c-0.2,1-0.5,1.4-0.6,2.5c0,0.5,0.1,1.4,0.5,2
                    c1,0.3,2.4,0.8,3.2,1c-0.7-2.3,0-3.6,0.3-5.2c0.4-2,0.6-3.8-1.4-5.2c3-1,4.4-3,4.5-5.8C160.3,329.6,158.8,327.8,156.1,326.9z
                    M156.4,333c-0.5,1.9-1.8,2.8-3.6,2.4c-2-0.5-3.9-1-6-1.6c0.5-2,1.1-4,1.6-6c2.1,0.6,4.1,1.1,6.1,1.7
                    C156.3,330.1,156.9,331.3,156.4,333z"/>
                <path fill="#010202" d="M225.9,323.7c-3.4,0.7-6.7,1.8-10.1,2.8c2,6.6,4,13,6,19.5c3.1-1,6.1-1.8,9-2.9c3-1.1,4.8-3.3,5.2-6.5
                    c0.4-3.9-0.6-7.5-3.2-10.4C230.9,324.1,228.5,323.1,225.9,323.7z M230.1,339.9c-1.8,0.9-3.6,1.5-5.6,2.3c-1.5-4.8-2.9-9.2-4.3-14
                    c2.2-0.4,4.3-1,6.4-1.2c1.7-0.2,3,0.7,3.9,2.1c1.3,2,2,4.2,2.1,6.5C232.6,337.4,231.8,339,230.1,339.9z"/>
                <polygon fill="#010202" points="176.7,345.9 172.4,330 167.2,329.6 165.7,349.8 169.2,350 170.2,334.8 174.6,350.4 177.8,350.6 
                    184.4,335.7 183.3,351.1 186.8,351.3 188.2,331.1 183.2,330.6 	"/>
                <path fill="#010202" d="M132.3,319.1l-2.5-1.1l-0.7-0.3l-14.5,16l3.6,1.5l3.7-4.3l7.3,3.1l-0.2,5.6l3.6,1.5l0.2-21.7L132.3,319.1
                    L132.3,319.1z M129.3,330.8l-5.4-2.2l5.7-6.5L129.3,330.8z"/>
                <path fill="#010202" d="M202.8,329.3L202.8,329.3l-2.7,0.3l-0.7,0.1l-4.7,21.1l3.8-0.5l1.2-5.6l7.9-1l2.6,5l3.9-0.5l-10.5-19
                    L202.8,329.3z M200.2,341.7l1.8-8.5l4,7.7L200.2,341.7z"/>
                <path fill="#010202" d="M247.3,315.3l-0.6,0.2l0-0.1l-2.5,1l-0.7,0.3l1.1,21.6l3.6-1.5l-0.4-5.7l7.3-3l3.8,4.1l3.6-1.5L247.3,315.3z
                    M247.5,328.2l-0.6-8.7l6,6.4L247.5,328.2z"/>
            </g>
        </svg>
    )
};

export default LogoAra;
