const OpenEye =  ({ width = "30px", fill="#BBB" }) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 36 36" xmlSpace="preserve" width={width} fill={fill}>
          <path d="M18,10.2C7.2,10.2,1.1,18,1.1,18c0,0,6,7.8,16.8,7.8s16.9-7.8,16.9-7.8c0,0-6-7.8-16.8-7.8ZM18,24.7c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7,6.7,3,6.7,6.7-3,6.7-6.7,6.7Z"/>
          <path d="M18,13.5c0-.3,0-.6.1-.9,0,0,0,0-.1,0-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4,5.4-2.4,5.4-5.4-.1-1.2-.3-1.7c-.5.3-1.1.5-1.8.5-1.8,0-3.3-1.5-3.3-3.3Z"/>
        </svg>
    );
};

export default OpenEye;
