import { EditIcon, PlayIcon, PauseIcon, DeleteIcon } from '../../../../assets';

import style from '../row.module.scss';


const SubscriberRow = ({i, subscriber, columns, subsPerPage, currentPage, theme, playPauseHandler, deleteHandler, user, editHandler}) => {

    return (
        <div className={style.main_row_content} style={{background: i % 2 === 0 ? `${theme.background}` : `${theme.darkBackground}` }}>
            <p className={style.row} style={{color:`${theme.text}`}}>{(subsPerPage * (currentPage - 1))+ (i + 1)}</p>
            <p className={`${style.sub_tuition} ${style.small_bold_text}`} style={ subscriber?.status === 'active' ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`} }>{subscriber?.tuition}</p>
            <div className={style.sub_row}>
                
                <div className={style.sub_data}>
                    
                    {
                        columns.apellido
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.lastName}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.nombre
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.firstName}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.dni
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.dni ? subscriber.dni : '---'}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.nacimiento
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.birth_date ? subscriber.birth_date : '---'}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.genero
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.gender ? subscriber.gender : '---'}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.mail
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.email ? subscriber.email : '---'}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.phone
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.phone ? subscriber.phone : '---'}</p>
                            </div>
                        </div>
                    }
                 
                    {
                        columns.personal
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.personal ? subscriber.personal : '---'}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.jerarquia
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.hierarchy ? subscriber.hierarchy : '---'}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.destino
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.destination?.cuatrigrama ? subscriber?.destination?.cuatrigrama : '---0'}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.zona
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.destination?.zone ? subscriber.destination.zone : '---'}</p>
                            </div>
                        </div>
                    }

                    {
                        columns.descripcion
                        &&
                        <div className={style.id_title}>
                            <div className={style.sub_column_content}>
                                <p className={`${style.item_content} ${style.light_text}`} style={{color:`${theme.text}`}}>{subscriber?.destination?.description ? subscriber.destination.description : '---'}</p>
                            </div>
                        </div>
                    }


                </div>
                
                <div className={style.sub_icons}>
                
                    <div onClick={() => editHandler(subscriber)} style={{cursor: 'pointer'}}>
                        <EditIcon width='19px' height='19px' fill={theme.alttext} />
                    </div>
                    {
                        subscriber.status === 'pending' ?
                        <div onClick={() => playPauseHandler(subscriber?.id, 'active')} style={{cursor: 'pointer'}}>
                            <PlayIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                        :
                        <div onClick={() => playPauseHandler(subscriber?.id, 'pending')} style={{cursor: 'pointer'}}>
                            <PauseIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                    }
                    {
                        user?.role === 'Admin' &&
                        <div onClick={() => deleteHandler(subscriber?.id)} style={{cursor: 'pointer'}}>
                            <DeleteIcon width='17px' fill={theme.primary}/>
                        </div>
                    }
                </div>
              
            </div>
        </div>
    )
};

export default SubscriberRow;
