const PublicationIcon = ({width='36px', height='36px', fill}) => {
    return (
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 36 36" xmlSpace="preserve">

            <path fill={fill} d="M33.4,35.9H2.6c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h30.8c1.4,0,2.5,1.1,2.5,2.5S34.8,35.9,33.4,35.9z"/>
            <path fill={fill} d="M33.4,28.6H2.6c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h30.8c1.4,0,2.5,1.1,2.5,2.5S34.8,28.6,33.4,28.6z"/>
            <path fill={fill} d="M33.4,21.3H2.6c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h30.8c1.4,0,2.5,1.1,2.5,2.5S34.8,21.3,33.4,21.3z"/>
            <path fill={fill} d="M15.1,13.9H2.6c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h12.4c1.4,0,2.5,1.1,2.5,2.5S16.5,13.9,15.1,13.9z"/>
            <path fill={fill} d="M15.1,6.6H2.6c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h12.4c1.4,0,2.5,1.1,2.5,2.5S16.5,6.6,15.1,6.6z"/>
            <path fill={fill} d="M34,14H21.8c-1.1,0-2-0.7-2-1.6V1.6c0-0.7,0.5-1.3,1.3-1.5c0.8-0.2,1.6-0.1,2.2,0.4l12.2,10.8
            c0.5,0.5,0.7,1.2,0.4,1.8C35.5,13.6,34.8,14,34,14z M23.8,10.8h5.6l-5.6-5V10.8z"/>
        </svg>
    )
};

export default PublicationIcon;