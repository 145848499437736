// import loader from '../../images/assets/loader.svg';
import style from './loader.module.scss';

const Loader = ( { width = 60, background } ) => {
    return (
        <div className={style.loader_background} style={{background: `${background}`}}>
            <div className={style.loader} style={{width:`${width}px`,height:`${width}px`}}></div>
        </div>
    );
}

export default Loader;
