import { useContext } from 'react';
import { ModalContext } from '../../contexts';

import { OkImg, ErrorImg, WarnImg, TimeImg } from '../../assets';

import style from './modalmessage.module.scss';



const ModalMessage = () => {

    const width =  '36px';
    const height = '36px';

    const { text, type, canClose, visible, closeModal } = useContext(ModalContext);

    return (
        <>
            <div className={ visible ? `${style.modal_main_container} ${style.on}`  : `${style.modal_main_container} ${style.off}`}>
                
                <div className={style.modal}>
                    <div className={type === "time" ? style.image_notif_rotate : style.image_notif}>
                        { type === "error" && <ErrorImg width={width} height={height} />}
                        { type === "ok" && <OkImg  width={width} height={height} />}
                        { type === "time" && <TimeImg  width={width} height={height} />}
                        { type === "warn" && <WarnImg  width={width} height={height} />}
                    </div>

                    <div className={style.content}>
                        {text}
                    </div>
                    { canClose && <div className={style.close_modal} onClick={closeModal}>cerrar</div> }
                </div>

            </div>
        </>
    )
};


export default ModalMessage;
