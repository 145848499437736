export const keys = {
    THEME: 'ust',
    DARKLIGHT: 'usts',
    TOKEN: 'abfbetwj',
    USER:  'udflseflid',
    EDITINGPUB: 'cedp',
    EDITINGPUBID: 'cepid',
    CATEGORIES: 'categories',
    COMPONENTS: 'components',
    SECTIONS: 'sections',
    TAGS: 'tags',
};
