import style from './toggle.module.scss';


const ToggleThemeSwitch = ({ handleSaveThemeOnProfile, darkLight}) => {
    return (
        <label className={style.switch}>
            <input
                type="checkbox"
                checked={darkLight ? true : false}
                onChange={handleSaveThemeOnProfile}
            />
            <span className={style.slider}></span>
        </label>
    );
}

export default ToggleThemeSwitch;
