import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppContextProvider, ModalContextProvider, ThemeContextProvider, UserContextProvider } from './contexts';
import { BrowserRouter } from 'react-router-dom';

import App from './App';


ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <AppContextProvider>
            <UserContextProvider>
                <ModalContextProvider>
                    <ThemeContextProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </ThemeContextProvider>
                </ModalContextProvider>
            </UserContextProvider>
        </AppContextProvider>
    </React.StrictMode>
);
