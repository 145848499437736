const storage = localStorage;

const string = (data) => JSON.stringify(data);
const parse = (data) => JSON.parse(data);


export const saveToStorage = (key, data) => {
    return storage.setItem(key, string(data));
};

export const getFromStorage = (key) => {
    return parse(storage.getItem(key));
};

export const deleteFromStorage = (key) => {
    return storage.removeItem(key);
}