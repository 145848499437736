import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;


const destinationsAPI = async () => {
    try {
        const allDests = await fetch(`${apipath}/subscriptions/getalldestinations`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': `Bearer ${apikey}`
            }
        })
        return await allDests.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener los destinos. ${error}`,
            data: []
        }
    }
}

const allSubscribersAPI = async (limit, offset, token) => {
    try {
        const list = await fetch(`${apipath}/admin/subscribers/all?limit=${limit}&offset=${offset}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await list.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener todos los suscriptores ${error}`
        }
    }
};

const allSubscribersByParamAPI = async (param, value, limit, offset, token) => {
    try {
        const listByParam = await fetch(`${apipath}/admin/subscribers/byparam?param=${param}&value=${value}&limit=${limit}&offset=${offset}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await listByParam.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener todos los suscriptores ${error}`
        }
    }
};

const updateSubscriberAPI = async (data, id, token) => {
    try {
        const subData = {
            data,
            id
        }
        const updateSubscriber = await fetch(`${apipath}/admin/subscribers/update`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(subData)
        });
        return await updateSubscriber.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener todos los suscriptores ${error}`
        }
    }
};

const getSubscribersExcelListAPI = async (token) => {
    try {        
        const list = await fetch(`${apipath}/admin/subscribers/downloadlist`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await list.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener la lista de suscriptores ${error}`
        }
    }
};

const getSubscribersExcelListForLabelsAPI = async (token) => {
    try {        
        const list = await fetch(`${apipath}/admin//subscribers/downloadlistlabels`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            }
        });
        return await list.json();
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar obtener la lista de suscriptores ${error}`
        }
    }
};

export { destinationsAPI, allSubscribersAPI, allSubscribersByParamAPI, updateSubscriberAPI, getSubscribersExcelListAPI, getSubscribersExcelListForLabelsAPI };
