import { useState, useContext } from 'react';

import { MainLayOut } from "../../layouts";
import { AppContext, UserContext, MenuContext, ModalContext, ThemeContext } from '../../contexts';

import { OpenEye, ClosedEye, AddIcon } from '../../assets';

import { changePassAPI, uploadImageProfileAPI } from '../../services';

import style from './settings.module.scss';
import { error_type } from '../../models';

const defaultFieldValues = {
    field01: '',
    field02: '',
    field03: '',
}
const defaultFieldTypes = {
    field01: 'password',
    field02: 'password',
    field03: 'password',
}


const Settings = () => {

    const { user, saveUser, deleteUser, token, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);

    const [ changePassModal, setChangePassModal ] = useState(false);
    const [ changeImageModal, setChangeImageModal ] = useState(false);
    const [ inputValues, setInputValues ] = useState(defaultFieldValues);
    const [ inputType, setInputType ] = useState(defaultFieldTypes);

    const [ imageToUpload, setImageToUpload ] = useState(null);
    const [ imageURL, setImageURL ] = useState(null);

    const handleResetPassForm = () => {
        setInputValues(defaultFieldValues);
        setInputType(defaultFieldTypes);
        setChangePassModal(false);
    };
    const handleChangeValues = (e, id) => {
        setInputValues(prev => {
            const newData = {...prev};
            newData[id] = e.target.value;
            return newData;
        });
    }
    const handleShowPasswords = (id) => {
        if (inputType[id] === "password") {
            setInputType(prev => {
                const newData = {...prev};
                newData[id] = 'text';
                return newData;
            });
            return;
        }
        setInputType(prev => {
            const newData = {...prev};
            newData[id] = 'password';
            return newData;
        });
        return;
    };
    const handleChangePassword = () => {
        const data = {
            password: inputValues.field01,
            newPassword: inputValues.field02,
        };
        changePassAPI(data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser();
                    deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            handleResetPassForm();
            return;
        })
    };

    const handleResetImageUpload = () => {
        setChangeImageModal(false);
        setImageURL(null);
        setImageToUpload(null);
    };
    const handleCancelImage = () => {
        setImageURL(null);
        setImageToUpload(null);
    };
    const uploadImage = (e) => {
        const file = e.target.files[0];
        if (file.size > 409600) {
            activateNotification('La imagen no puede ser mayor a 400 kb', 'error', true, 4000);
            setImageURL(null);
            setImageToUpload(null);
            e.target.value = null;
            return;
        }
        const value = URL.createObjectURL(file);
        setImageURL(value);
        setImageToUpload(file);
    };

    const handleUploadImage = (e) => {

        const formData = new FormData();
        formData.append('enctype','multipart/form-data');
        formData.append('profile', imageToUpload);
        activateNotification('Enviando imagen al servidor', 'time', true);

        uploadImageProfileAPI(formData, token)
        .then(res => {
            console.log(res);
            if (res.error) {
                formData.delete('profile');
                if (res.errortype === error_type.AUTH) {
                    deleteToken();
                    deleteUser();
                    activateNotification(`${res.message}`, 'error', true, 3800);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 3800);
                formData.delete('profile');
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 3800);
            saveUser(res.data);

            setImageToUpload(null);
            
            formData.delete('profile');
            return;
        });
    };
    


    return (
        <MainLayOut>

            <div className={style.title_container}>
                <p className={style.main_title} style={{color:`${theme.text}`}}>Ajustes de cuenta</p>
            </div>

            <div></div>

            <div className={style.title_container}>
                <p className={style.main_subtitle} style={{color:`${theme.text}`}}>Contraseña</p>
                <div>
                    {
                        changePassModal
                        ?
                        <button className={style.cancel} onClick={handleResetPassForm}>cancelar</button>
                        :
                        <button className={style.submit} onClick={()=>{setChangePassModal(true);setChangeImageModal(false)}}>Cambiar contraseña</button>
                    }
                </div>
            </div>            
          
            {
                changePassModal
                &&
                <>
                    <div className={style.block} style={{background: `${theme.background}`}}>


                        <div>
                            <p style={{color:`${theme.text}`}}>Contraseña actual</p>
                            <div className={style.input_eye}>
                            <input className={style.input} type={inputType.field01} value={inputValues.field01 || ''} onChange={(e)=>handleChangeValues(e,'field01')}/>
                                
                                 {
                                    inputValues.field01 !== ''
                                    &&  
                                    <div onClick={()=>handleShowPasswords('field01')}>
                                        {
                                            inputType.field01 === 'password' ?
                                            <ClosedEye fill={theme.text}/> :
                                            <OpenEye fill={theme.text}/>
                                        }
                                    </div>
                                }
                            
                            </div>
                        </div>
                        
                        <div>
                            <p style={{color:`${theme.text}`}}>Nueva contraseña</p>
                            <div className={style.input_eye}>
                                <input className={style.input} type={inputType.field02} value={inputValues.field02 || ''} onChange={(e)=>handleChangeValues(e,'field02')}/>
                                    {
                                        inputValues.field02 !== ''
                                        &&
                                        <div onClick={()=>handleShowPasswords('field02')}>
                                            {
                                                inputType.field02 === 'password' ?
                                                <ClosedEye fill={theme.text}/> :
                                                <OpenEye fill={theme.text}/>
                                            }
                                        </div>
                                    }
                            </div>
                        </div>
                        
                        <div>
                            <p style={{color:`${theme.text}`}}>Repetir nueva contraseña</p>
                            <div className={style.input_eye}>
                            <input className={style.input} type={inputType.field03} value={inputValues.field03 || ''} onChange={(e)=>handleChangeValues(e,'field03')}/>
                               {   
                                    inputValues.field03 !== ''
                                    &&  
                                    <div onClick={()=>handleShowPasswords('field03')}>
                                        {
                                            inputType.field03 === 'password' ?
                                            <ClosedEye fill={theme.text}/> :
                                            <OpenEye fill={theme.text}/>
                                        }
                                    </div>
                                    }
                            </div>
                        </div>
                        {
                            inputValues.field01 && inputValues.field02 && inputValues.field03 && inputValues.field02 === inputValues.field03 && 
                            <button className={style.submit} onClick={handleChangePassword}>Enviar</button>
                        }
                    </div>

                </>
            }

            <div className={style.title_container}>
                <p className={style.main_subtitle} style={{color:`${theme.text}`}}>Imagen de Perfil</p>
                <div>
                    {
                        changeImageModal
                        ?
                        <button className={style.cancel} onClick={handleResetImageUpload}>cancelar</button>
                        :
                        <button className={style.submit} onClick={()=>{setChangePassModal(false);setChangeImageModal(true)}}>Cambiar imagen</button>
                    }
                </div>
            </div>
            
            {
                changeImageModal
                &&
                <>
                    {
                        imageToUpload
                        ?
                        <div style={{display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center',gap:'16px'}}>
                            <div className={style.image_uploaded} style={{backgroundImage:`url(${imageURL})`}}></div>
                            <div style={{display:'flex', flexFlow:'row no wrap',gap:'12px'}}>
                                <button className={style.cancel} onClick={handleCancelImage}>cancelar</button>
                                <button className={style.submit} onClick={handleUploadImage}>Enviar</button>
                            </div>
                        </div>
                        :
                        <label htmlFor='banneruploader' className={style.image_block} style={{background: `${theme.background}`}}>
                            <AddIcon width='22px' height='22px' fill={theme.strokeColor02} />
                            <input
                                style={{display:'none'}}
                                type="file"
                                id='banneruploader'
                                accept="image/png, image/jpeg, image/jpg, image/webp, image/gif image/heic"
                                onChange={(e) => uploadImage(e)}
                            />
                            <p className={style.light_text} style={{color: `${theme.text}`}}>Seleccioná una imagen</p>
                            <p className={style.small_light_text} style={{color: `${theme.text}`}}>max: 400 kb</p>
                        </label>
                    }   
                </>
            }

        </MainLayOut>
    );
}

export default Settings;
