const EditIcon = ({ width = "36px", height = "36px", fill="white" }) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 25.2 25.2" xmlSpace="preserve" width={width} height={height} fill={fill}>
            <g>
                <polygon points="3.4,17.5 0.9,24.3 7.7,21.8"/>
                <g>
                    <rect x="19.9" y="1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 3.3488 16.1489)" width="2.6" height="6.1" fill={fill} />
                    <rect x="3.9" y="9.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.9114 12.7274)" width="18" height="6.1" fill={fill} />
                </g>
            </g>
        </svg>
    );
};

export default EditIcon;