const OkImg = ({ width, height}) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 56 56" width={width} height={height} xmlSpace="preserve">
            <path fill="#2BB500" d="M46,0H10C4.5,0,0,4.5,0,10v36c0,5.5,4.5,10,10,10h36c5.5,0,10-4.5,10-10V10C56,4.5,51.5,0,46,0z M40.7,16.9
                L28.6,41.8c-0.2,0.4-0.6,0.7-0.9,1c-0.1,0.1-0.3,0.1-0.4,0.2h-0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0
                c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.3-0.1-0.4-0.2
                c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.1-0.2-0.2l-8.3-11.5c-0.9-1.2-0.6-2.9,0.6-3.8
                c1.2-0.9,2.9-0.6,3.8,0.6l5.7,7.9l10.2-20.6c0.6-1.3,2.3-1.9,3.6-1.2C40.7,13.9,41.4,15.6,40.7,16.9z"
            />
        </svg>
    )
};

export default OkImg;
