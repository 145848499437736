import { createContext, useState } from 'react';


const ModalContext = createContext();


const ModalContextProvider = ({ children }) => {

    const [ text,  setText  ] = useState('');
    const [ delay, setDelay ] = useState(0);
    const [ canClose, setCanclose ] = useState(false);
    const [ type, setType ]   = useState('');

    const [ visible, setVisible ] = useState(false);


    const activateNotification = (text, type, canClose, delay = null, ) => {
        setText(text);
        setType(type);
        setCanclose(canClose);
        setDelay(delay);
        setVisible(true);

        if (delay === null) {
            return;
        };

        const timer = setTimeout(() => {
            setVisible(false);
        }, delay);

        return () => clearTimeout(timer);
    };

    const closeModal = () => {
        setVisible(false);
    };

    return (
        <ModalContext.Provider value={{
            text, type, canClose, delay, visible, closeModal, activateNotification
        }}>
            {children}
        </ModalContext.Provider>
    )
};

export default ModalContext;
export { ModalContextProvider };