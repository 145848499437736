const DashboardIcon = ({width='36px', height='36px', fill}) => {
    return (
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 36 36" xmlSpace="preserve">
            <path fill={fill} d="M14.3,21.4H2.5c-1.4,0-2.5-1.1-2.5-2.5L0,2.5C0,1.1,1.1,0,2.5,0l11.8,0c1.4,0,2.5,1.1,2.5,2.5v16.4
                C16.8,20.3,15.7,21.4,14.3,21.4z"/>
            <path fill={fill} d="M14.3,36H2.5C1.1,36,0,34.9,0,33.5l0-7.1c0-1.4,1.1-2.5,2.5-2.5h11.8c1.4,0,2.5,1.1,2.5,2.5v7.1
                C16.8,34.9,15.7,36,14.3,36z"/>
            <path fill={fill} d="M21.7,14.6h11.8c1.4,0,2.5,1.1,2.5,2.5v16.4c0,1.4-1.1,2.5-2.5,2.5H21.7c-1.4,0-2.5-1.1-2.5-2.5V17.1
                C19.2,15.7,20.3,14.6,21.7,14.6z"/>
            <path fill={fill} d="M21.7,0l11.8,0C34.9,0,36,1.1,36,2.5v7.1c0,1.4-1.1,2.5-2.5,2.5H21.7c-1.4,0-2.5-1.1-2.5-2.5V2.5
                C19.2,1.1,20.3,0,21.7,0z"/>
        </svg>
    )
};

export default DashboardIcon;