import { useRef, useContext } from 'react';

import { GmLogo } from '../../assets';
import { loginAPI } from '../../services/admin';

import { ModalContext, UserContext, ThemeContext } from '../../contexts';

import { Navigate } from 'react-router-dom';
import { error_type } from '../../models/errors';
import { publicRoutes } from '../../routes';

import style from './login.module.scss';


const LogIn = () => {

    const { activateNotification } = useContext(ModalContext);
    const { user, saveUser, token, saveToken } = useContext(UserContext);
    const { setDarkLight } = useContext(ThemeContext);

    const handleLogin = (e) => {
        e.preventDefault();

        activateNotification('Enviando datos al servidor', 'time', true, null);
        const email = e.target.email.value;
        const password = e.target.password.value;

        const data = { email, password };

        loginAPI(data)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.PASSWORD) {
                    activateNotification(`${res.message}`, 'error', true, null);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            saveUser(res.data.user);
            saveToken(res.data.token);
            setDarkLight(res.data.user.darkTheme);
            <Navigate replace to={publicRoutes.HOME} />
            return;
        })
    };
    if (token) {
        return <Navigate replace to={publicRoutes.HOME} />
    };


    return (
        <div className={style.main_container}>
            <div className={style.logo}>
                <GmLogo width='300px' dark ='#FFF'/>
            </div>
            <div className={style.form_container}>
                <form className={style.form} onSubmit={handleLogin}>
                    <input className={style.input} name="email" type="email" placeholder="email" />
                    <input className={style.input} name="password" type="password" placeholder="contraseña"/>
                    <button className={style.submit} type='submit'>INGRESAR</button>
                </form>
            </div>
        </div>
    )
};

export default LogIn;
