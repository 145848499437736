import { Routes, Route, Navigate } from 'react-router-dom';

const RouteNotFound = ({children, path}) => {
    return (
        <Routes>
            <Route path='*' element={<Navigate replace to={path} />} />
            { children }
        </Routes>
    )
};

export default RouteNotFound;
