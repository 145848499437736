export const publicRoutes = {
    HOME: '/',
    VERIFYMAILACCOUNT: '/verifyemailaccount',
    LOGIN: '/login',
};

export const privateRoutes = {
    SUBSCRIBERS: '/suscriptores',
    LISTOFSUBSCRIBERS: '/suscriptores/lista',
    NEWSUBSCRIBER: '/suscriptores/nuevo',
    MODIFYSUBSCRIBER: '/suscriptores/modificar',

    
    SETTINGS: '/settings',
};
