const WarnImg = ({width, height}) => {
    return (
        <svg x="0px" y="0px" width={width} height={height} viewBox="0 0 56 56" xmlSpace="preserve">
            <path fill="#F2B907" d="M46,0H10C4.5,0,0,4.5,0,10v36c0,5.5,4.5,10,10,10h36c5.5,0,10-4.5,10-10V10C56,4.5,51.5,0,46,0z M24.6,14.1
                c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4v17.4c0,1.9-1.5,3.4-3.4,3.4c-1.8,0-3.3-1.5-3.4-3.4V14.1z M28,45.3
                c-2.2,0-3.9-1.8-3.9-3.9c0-2.2,1.8-3.9,3.9-3.9c2.2,0,3.9,1.7,3.9,3.9S30.1,45.3,28,45.3z"/>
        </svg>
    )
};

export default WarnImg;
