import config from '../config';

const apikey = config.api_key;
const apipath = config.api_path;


const loginAPI = async (data) => {
    try {        
        const login = await fetch(`${apipath}/admin/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': `Bearer ${apikey}`
            },
            body:  JSON.stringify(data)
        });
        return await login.json()
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar loguearse: ${error}`
        }
    }
};

const changePassAPI = async (data, token) => {
    try {        
        const login = await fetch(`${apipath}/admin/changepassword`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            body: JSON.stringify(data)
        });
        return await login.json()
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar cambiar la contraseña: ${error}`
        }
    }
};

const uploadImageProfileAPI = async (data, token) => {
    try {
        const uploadImage = await fetch(`${apipath}/admin/changeuserimage`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`
            },
            enctype: 'multipart/form-data',
            body: data
        });
        return await uploadImage.json();
    }
    catch (error) {
        return {error: true, message: `Ocurrió un error y no se pudo cargar la imagen:${error}`};
    }
};

const updateAdminThemeAPI = async (data, token) => {
    try {        
        const updateTheme = await fetch(`${apipath}/admin/settheme`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`,
                'x-api-key': `Bearer ${apikey}`,
            },
            body:  JSON.stringify(data)
        });
        return await updateTheme.json()
    }
    catch (error) {
        return {
            error: true,
            message: `Ocurrió un error en el servidor al intentar guardar la preferencia: ${error}`
        }
    }
};


export { loginAPI, changePassAPI, uploadImageProfileAPI, updateAdminThemeAPI };
