import style from '../row.module.scss';

const Results = ({ subsPerPage, handleChangeSubsPerPage, currentPage, prevPage, nextPage, setPageValue, totalPages, theme} ) => {
    return (        
        <div className={style.results}>

            <div className={style.pagination_per_page} style={{color: `${theme.text}`}}>
                <p className={style.small_light_text}>resultados por página</p>
                <select className={style.selector} onChange={handleChangeSubsPerPage} >
                    <option selected={ subsPerPage == 10 ? true : false } value="10">10</option>
                    <option selected={ subsPerPage == 50 ? true : false } value="50">50</option>
                    <option selected={ subsPerPage == 100 ? true : false } value="100">100</option>
                    <option selected={ subsPerPage == 200 ? true : false } value="200">200</option>
                </select>
            </div>

            <div className={style.pagination_pages}>
                <p className={style.small_light_text} style={{color: `${theme.text}`}}>página</p>
                <input className={`${style.input} ${style.small_input}`} type="text" value={currentPage} placeholder={currentPage.toString()} onChange={setPageValue} />
                <p className={style.small_light_text} style={{color: `${theme.text}`}}>de {totalPages}</p>
                <div className={style.pagination_buttons}>
                    <button className={`${style.submit} ${style.prev_next} ${style.prev}`} onClick={prevPage}></button>
                    <button className={`${style.submit} ${style.prev_next} ${style.next}`} onClick={nextPage}></button>
                </div>
            </div>

        </div>
    );
};


export default Results;
