import { createContext, useState, useEffect } from 'react';
import { useSetGetDelete } from '../../hooks';
import { keys } from '../../models';


const customTheme = {
    light: {
        on: '#71b70b',
        off: '#ff0653',
        inputBackground: '#f5f5f5',
        barColor: [
            '#92e82a', '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a',
            '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a', '#1eeaef',
            '#92e82a', '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a',
            '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a', '#1eeaef',
            '#92e82a', '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a',
            '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a', '#1eeaef',
        ],
        strokeColor01: '#fa114f',
        strokeColor02: '#41cc00',
        strokeColor03: '#009ddb',
        strokeColor04: '#ff9800',
        strokeColor05: '#cccc00',
        strokeColor06: '#0072ff',
        blackwhite: '#000',
        primary: '#FF5500',
        alt: '#007ce2',
        placeholder: '#333',
        text: '#333',
        invertedtext: 'rgb(230, 230, 230)',
        alttext: '#777',
        textColor: '#202528',
        darkBackground: 'rgb(247, 247, 249)',
        background: 'rgb(255, 255, 255)',
        invertedbackground: '#202528',
        alphaBackground: 'rgba(255 255 255/0.8)',
    },
    dark: {
        on: '#71b70b',
        off: '#ff0653',
        inputBackground: '#EEE',
        barColor: [
            '#92e82a', '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a',
            '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a', '#1eeaef',
            '#92e82a', '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a',
            '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a', '#1eeaef',
            '#92e82a', '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a',
            '#1eeaef', '#ffc200', '#ffff00', '#0090ff', '#92e82a', '#1eeaef',
        ],
        strokeColor01: '#fa114f',
        strokeColor02: '#92e82a',
        strokeColor03: '#1eeaef',
        strokeColor04: '#ffc200',
        strokeColor05: '#ffff00',
        strokeColor06: '#0090ff',
        blackwhite: '#CCC',
        primary: '#FF6900',
        alt: '#007ce2',
        placeholder: '#BBB',
        alttext: '#777',
        text: 'rgb(230, 230, 230)',
        invertedtext: '#333',
        textColor: 'rgb(120, 120, 120)',
        background: '#202528',
        invertedbackground: 'rgb(255, 255, 255)',
        darkBackground: '#151619',
        alphaBackground: 'rgba(0 0 0/0.8)',
    }
};

const ThemeContext = createContext();

const ThemeContextProvider = ({children}) => {

    const [ theme, setTheme ] = useState(customTheme.light);
    const [ darkLight, setDarkLight, deleteDarkLight ] = useSetGetDelete(false, keys.DARKLIGHT);

    const changeTheme = () => {
        setDarkLight(!darkLight);
    }

    useEffect( () => {
        darkLight ? setTheme(customTheme.dark) : setTheme(customTheme.light);
    }, [darkLight]);


    return (
        <ThemeContext.Provider value={{
            changeTheme,
            theme, setTheme,
            darkLight, setDarkLight, deleteDarkLight
        }}>
            { children }
        </ThemeContext.Provider>
    )
};

export default ThemeContext;
export { ThemeContextProvider };