const ErrorImg = ({width, height}) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 56 56" width={width} height={height} xmlSpace="preserve">
            <path fill="#E5350C" d="M46,0H10C4.5,0,0,4.5,0,10v36c0,5.5,4.5,10,10,10h36c5.5,0,10-4.5,10-10V10C56,4.5,51.5,0,46,0z M40.7,36.4
            c1.2,1.2,1.2,3.1,0,4.3s-3.1,1.2-4.3,0L28,32.3l-8.4,8.4c-1.2,1.2-3.1,1.2-4.3,0s-1.2-3.1,0-4.3l8.4-8.4l-8.4-8.4
            c-1.2-1.2-1.2-3.1,0-4.3s3.2-1.2,4.4,0l8.3,8.4l8.4-8.4c1.2-1.2,3.1-1.2,4.3,0s1.2,3.1,0,4.3L32.3,28L40.7,36.4z"/>
        </svg>
    )
};

export default ErrorImg;
