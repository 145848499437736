import { useEffect, useState, useRef, useContext } from 'react';

import download from 'downloadjs';

import { Loader, SearchIcon, DeleteIcon } from '../../assets';

import { MainLayOut } from '../../layouts';
import { UserContext, ThemeContext, ModalContext } from '../../contexts';

import { useNavigateTo } from '../../hooks';
import { privateRoutes } from '../../routes';
import { error_type } from '../../models';

import { Results, SubTitlesRow, SubscriberRow } from './components';

import { destinationsAPI, allSubscribersAPI, allSubscribersByParamAPI, updateSubscriberAPI, getSubscribersExcelListAPI, getSubscribersExcelListForLabelsAPI } from '../../services';

import  style from './list.module.scss';



const SubscribersList = () => {

    const [ goto ] = useNavigateTo();

    const { user, deleteUser, token, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);

    const valueRef = useRef(null);

    const [ columns, setColumns ] = useState({
        nombre: true,
        apellido: true,
        dni: false,
        nacimiento: false,
        genero: false,
        mail: false,
        telefono: true,
        personal: true,
        jerarquia: true,
        destino: true,
        zona: true,
        descripcion: false,
    });

    const [ searchParam, setSearchParam ] = useState('all');

    const [ allSubscribers, setAllSubscribers ] = useState([]);
    const [ totalSubscribers, setTotalSubscribers ] = useState("0");

    const [ destinations, setDestinations ] = useState([]);

    const [ currentPage, setCurrentPage ] = useState(1);
    const [ totalPages, setTotalPages ] = useState(10);
    const [ subsPerPage, setSubsPerPage ] = useState(10);

    const [ inputSearch, setInputSearch ] = useState('');

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);

    const [ openEditModal, setOpenEditModal ] = useState(false);
    const [ subToEditData, setSubToEditData ] = useState({});
    const [ subEditedData, setSubEditedData ] = useState({});
    const [ subEditedDataToSend, setSubEditedDataToSend ] = useState({});

    const handleEditSubscriber = (data) => {
        setOpenEditModal(true);
        setSubToEditData(data);
        setSubEditedData(data);
    };
    const handleAbortSubscriberEdition = () => {
        setOpenEditModal(false);
        setSubToEditData({});
        setSubEditedData({});
        setSubEditedDataToSend({});
    };
    const handleSubEditingData = (e) => {
        const name = e.target.name;
        const value = name === 'tuition' || name === 'dni' || name === 'destinationId' ? Number(e.target.value) : e.target.value === '' ? null : e.target.value;
        if ( name === 'personal' && value === 'civil') {
            // console.log(name)
            // console.log(value)
            setSubEditedData( prev => {
                const newData = {...prev};
                newData[name] = value;
                newData.hierarchy === '';
                newData.body === '';
                newData.task === '';
                return newData;
            });
            return;      
        }
        setSubEditedData( prev => {
            const newData = {...prev};
            newData[name] = value;
            return newData;
        });
        return;
    };
    const handleSendEditedSubDataToApi = () => {
        activateNotification(`Enviando datos al servidor`, 'time');
        setIsDisabled(true);
        updateSubscriberAPI(subEditedDataToSend, subToEditData.id, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(), deleteToken();
                    setTotalSubscribers('');
                    setTotalPages('');
                    setAllSubscribers([]);
                    setCurrentPage(1);
                    setIsLoading(false);
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true);
                setIsLoading(false);
                setIsDisabled(false);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 4000);
            setIsLoading(false);
            setIsDisabled(false);
            setOpenEditModal(false);
            const updatedSub = res.data;
            setAllSubscribers( prev => {
                const newData = [ ...prev ];
                const itemIndex = newData.findIndex( item => item.id === updatedSub.id);
                newData[itemIndex] = updatedSub;
                return newData;
            })
        })
    };

    const handleShowColumn = (item) => {
        setColumns( prev => {
            const newState = {...prev};
            newState[item] = newState[item] ? false : true;
            return newState;
        })
    };
    const handleDownloadFile = () => {
        getSubscribersExcelListAPI(token)
        .then( res => {
            if (res.error) {
                activateNotification('No se pudo descargar el archivo excel.', 'error', true);
                return;
            }
            const buffer = new Uint8Array(res.data.data).buffer;
            const blob = new Blob([buffer], { type: res.datatype });

            download(blob, res.filename);
            return;
        });
    };
    const handleDownloadFileLabels = () => {
        getSubscribersExcelListForLabelsAPI(token)
        .then( res => {
            if (res.error) {
                activateNotification(`No se pudo descargar el archivo excel. ${res.message}`, 'error', true);
                return;
            }
            const buffer = new Uint8Array(res.data.data).buffer;
            const blob = new Blob([buffer], { type: res.datatype });

            download(blob, res.filename);
            return;
        });
    };
    const handleGetAllSubs = (spp, currpg) => {
        setIsLoading(true);
        if (searchParam === 'all') {    
            allSubscribersAPI(spp, currpg, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        setTotalSubscribers('');
                        setTotalPages('');
                        setAllSubscribers([]);
                        setCurrentPage(1);
                        setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 5000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    setIsLoading(false);
                    return;
                };
                setAllSubscribers(res.data.rows);
                setTotalSubscribers(res.data.count);
                setTotalPages(Math.ceil(res.data.count / Number(spp)));
                setSubsPerPage(parseInt(res.limit));
                setCurrentPage(parseInt(res.offset));
                setIsLoading(false);
                return;
            })
        } else {
            let value;
            if (searchParam === 'status' || searchParam === 'personal') {
                value = inputSearch;
            } else {
                value = valueRef.current.value;
            }
            allSubscribersByParamAPI(searchParam, value, spp, currpg, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        setTotalSubscribers('');
                        setTotalPages('');
                        setAllSubscribers([]);
                        setCurrentPage(1);
                        setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 5000);
                        return;
                    }
                    setAllSubscribers([]);
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    setIsLoading(false);
                    return;
                };
                setAllSubscribers(res.data.rows);
                setTotalSubscribers(res.data.count);
                setTotalPages(Math.ceil(res.data.count / Number(spp)));
                setSubsPerPage(parseInt(res.limit));
                setCurrentPage(parseInt(res.offset));
                setIsLoading(false);
                return;
            })
        }
    };
    const handleChangeSubsPerPage = (e) => {
        setSubsPerPage(e.target.value);
        handleGetAllSubs(e.target.value, currentPage);
    };
    const setPageValue = (e) => {
        const value = Number(e.target.value);
        if (!isNaN(value) && value > 0 && value <= totalPages ) {
            setCurrentPage(value);
            handleGetAllSubs(subsPerPage, value);
        };
        return;
    };
    const prevPage = () => {
        if (currentPage === 1) return;
        const page = currentPage - 1;
        // cuando haya parametros de busqueda
        // searchSubsByParam(page);
        handleGetAllSubs(subsPerPage, page);

    };
    const nextPage = () => {
        if (currentPage === totalPages) return;
        const page = currentPage + 1;
        // cuando haya parametros de busqueda
        handleGetAllSubs(subsPerPage, page);
    };

    useEffect( () => {
        handleGetAllSubs(subsPerPage, currentPage);
    },[]);

    useEffect( () => {
        destinationsAPI()
        .then( res => setDestinations(res.data))
    },[]);

    useEffect(() => {
        const subscriber = { ...subEditedData };
        delete subscriber.id, subscriber.createdAt, subscriber.updatedAt, subscriber.deletedAt;
        Object.keys(subToEditData).forEach( key => {
            if (subToEditData[key] == subEditedData[key] || subEditedData[key] == null || subEditedData[key] == '' || subEditedData[key] == 'undefined') {
                delete subscriber[key];
            };
        });
        setSubEditedDataToSend(subscriber);
    },[subEditedData]);


    
    return (
        <MainLayOut>
            {
                isLoading
                ?
                <Loader background={theme.alphaBackground}/>
                :
                <>
                    <div className={style.title_container}>
                        <p className={style.main_title} style={{cursor:'pointer', color:`${theme.text}`}} onClick={()=>goto(privateRoutes.SUBSCRIBERS)}>
                            Suscriptores
                            <span className={style.main_subtitle} style={{color:`${theme.text}`}}> / Lista</span>
                        </p>
                    </div>

                    <div className={style.total_and_pagination} style={{background: `${theme.background}`}}>
                                
                        <div className={style.search_elements} style={{color:`${theme.text}`}}>
                            <select className={`${style.selector} ${style.big_selector}`} onChange={(e)=>{setSearchParam(e.target.value),setInputSearch('')}}>
                                <option selected={false} value='all'>buscar por</option>
                                <option selected={searchParam === 'all' ? true : false} value='all'>todas</option>
                                <option selected={searchParam === 'tuition' ? true : false} value='tuition'>matrícula</option>
                                <option selected={searchParam === 'firstName' ? true : false} value='firstName'>nombre</option>
                                <option selected={searchParam === 'lastName' ? true : false} value='lastName'>apellido</option>
                                <option selected={searchParam === 'dni' ? true : false} value='dni'>dni</option>
                                <option selected={searchParam === 'email' ? true : false} value='email'>correo</option>
                                <option selected={searchParam === 'personal' ? true : false} value='personal'>personal</option>
                                <option selected={searchParam === 'status' ? true : false} value='status'>estado</option>
                            </select>
                            {
                                searchParam !== 'all' && searchParam !== 'status' && searchParam !== 'personal'
                                &&
                                <input
                                    className={`${style.input} ${style.mid_input}`}
                                    placeholder='ingresar texto'
                                    ref={valueRef}
                                    onChange={ (e) => setInputSearch(e.target.value)}
                                    value={ inputSearch || '' }
                                    onKeyDown={ (e) => { if (e.key === 'Enter') { handleGetAllSubs(subsPerPage, 1) }}}
                                />
                            }
                            {
                                searchParam === 'status'
                                &&
                                <select className={`${style.selector} ${style.big_selector}`} onChange={(e)=>setInputSearch(e.target.value)}>
                                    <option selected={false}>seleccionar</option>
                                    <option selected={inputSearch === 'active' ? true: false} value='active'>activo</option>
                                    <option selected={inputSearch === 'pending' ? true: false} value='pending'>pendiente</option>
                                    <option selected={inputSearch === 'inactive' ? true: false} value='inactive'>inactivo</option>
                                </select>
                            }
                            {
                                searchParam === 'personal'
                                &&
                                <select className={`${style.selector} ${style.big_selector}`} onChange={(e)=>setInputSearch(e.target.value)}>
                                    <option selected={false}>seleccionar</option>
                                    <option selected={inputSearch === 'oficial' ? true : false} value='oficial'>Oficial</option>
                                    <option selected={inputSearch === 'suboficial' ? true : false} value='suboficial'>Suboficial</option>
                                    <option selected={inputSearch === 'civil' ? true : false} value='civil'>Civil</option>
                                </select>
                            }
                            <button className={style.submit} onClick={() => handleGetAllSubs(subsPerPage, 1)}>
                                <SearchIcon width='14px' height='14px' fill='white'/>
                            </button>
                            {
                                allSubscribers.length ?
                                <div onClick={() => {setAllSubscribers([]), setTotalSubscribers("0")}} style={{padding: '0 6px'}}>
                                    <DeleteIcon width='18px' height='18px' fill={theme.strokeColor01}/>
                                </div>
                                :
                                <></>
                            }
                        </div>

                        <p className={style.small_light_text} style={{color: `${theme.text}`}}>Total: {totalSubscribers} {totalSubscribers > 1 ? 'suscriptores' : 'suscriptor'}</p>
                    </div>

                    <Results subsPerPage={subsPerPage} handleChangeSubsPerPage={handleChangeSubsPerPage} currentPage={currentPage} prevPage={prevPage} nextPage={nextPage} setPageValue={setPageValue} totalPages={totalPages} theme={theme} />

                    <div className={style.subs_list}>
                        <div className={style.subs_list_selectors}>

                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>apellido</p>
                                <input type="checkbox" checked={columns.apellido ? true: false} onChange={()=>handleShowColumn('apellido')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>nombre</p>
                                <input type="checkbox" checked={columns.nombre ? true: false} onChange={()=>handleShowColumn('nombre')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>dni</p>
                                <input type="checkbox" checked={columns.dni ? true: false} onChange={()=>handleShowColumn('dni')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>nac</p>
                                <input type="checkbox" checked={columns.nacimiento ? true: false} onChange={()=>handleShowColumn('nacimiento')} />
                            </div>

                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>género</p>
                                <input type="checkbox" checked={columns.genero ? true: false} onChange={()=>handleShowColumn('genero')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>email</p>
                                <input type="checkbox" checked={columns.mail ? true: false} onChange={()=>handleShowColumn('mail')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>teléfono</p>
                                <input type="checkbox" checked={columns.phone ? true: false} onChange={()=>handleShowColumn('phone')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>personal</p>
                                <input type="checkbox" checked={columns.personal ? true: false} onChange={()=>handleShowColumn('personal')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>jerarquía</p>
                                <input type="checkbox" checked={columns.jerarquia ? true: false} onChange={()=>handleShowColumn('jerarquia')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>destino</p>
                                <input type="checkbox" checked={columns.destino ? true: false} onChange={()=>handleShowColumn('destino')} />
                            </div>
                            
                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>zona</p>
                                <input type="checkbox" checked={columns.zona ? true: false} onChange={()=>handleShowColumn('zona')} />
                            </div>

                            <div className={style.checker}>
                                <p style={{color:`${theme.text}`}}>descripción</p>
                                <input type="checkbox" checked={columns.descripcion ? true: false} onChange={()=>handleShowColumn('descripcion')} />
                            </div>

                        </div>

                        <SubTitlesRow columns={columns} theme={theme} />
                        {
                            allSubscribers.map( (subscriber, i) => {
                                return (
                                    <SubscriberRow key={subscriber.id} i={i} subscriber={subscriber} columns={columns} subsPerPage={subsPerPage} currentPage={currentPage} theme={theme} edithandler={()=>{}} playPauseHandler={()=>{}} deleteHandler={()=>{}} user={user} editHandler={handleEditSubscriber}/>
                                )
                            })
                        }
                    </div>

                    <Results subsPerPage={subsPerPage} handleChangeSubsPerPage={handleChangeSubsPerPage} currentPage={currentPage} prevPage={prevPage} nextPage={nextPage} setPageValue={setPageValue} totalPages={totalPages} theme={theme} />


                    <div className={style.downloads_container}>
                            <button className={style.submit} style={{margin:'0 6px',maxWidth:'400px'}} onClick={handleDownloadFile}>Descargar listado completo de suscriptores</button>
                            <button className={style.submit} style={{margin:'0 6px',maxWidth:'400px'}} onClick={handleDownloadFileLabels}>Descargar excel etiquetas</button>
                    </div>

                    {/* MODAL EDICION */}

                    <div className={openEditModal ? `${style.modal_edit_container} ${style.on}` : `${style.modal_edit_container} ${style.off}`}>
                        <div className={openEditModal ? `${style.modal_block} ${style.on_content}` : `${style.modal_block} ${style.off_content}`}>

                            <div className={style.main_subtitle} style={{fontSize:'22px',color:'#FF9900', margin: '50px'}}>
                                <span style={{color:'white'}}>Editar suscriptor</span> {subEditedData?.firstName} {subEditedData?.lastName}
                            </div>

                            <div className={style.form}>
                                
                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Matrícula</p>
                                    <input style={subToEditData?.tuition !== subEditedData?.tuition ? {border:'solid 4px red'} : {border:'none'}} className={style.edit_input} name='tuition' type='tel' value={subEditedData?.tuition || ''} onChange={(e) =>handleSubEditingData(e)}/>
                                </div>

                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Nombre</p>
                                    <input style={subToEditData?.firstName !== subEditedData?.firstName ? {border:'solid 4px red'} : {border:'none'}} className={style.edit_input} name='firstName' value={subEditedData?.firstName || ''} onChange={(e) =>handleSubEditingData(e)}/>
                                </div>

                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Apellido</p>
                                    <input style={subToEditData?.lastName !== subEditedData?.lastName ? {border:'solid 4px red'} : {border:'none'}} className={style.edit_input} name='lastName' value={subEditedData?.lastName || ''} onChange={(e) =>handleSubEditingData(e)}/>
                                </div>
                                
                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>DNI</p>
                                    <input style={subToEditData?.dni !== subEditedData?.dni ? {border:'solid 4px red'} : {border:'none'}} className={style.edit_input} name='dni' type='tel' value={subEditedData?.dni || ''} onChange={(e) =>handleSubEditingData(e)}/>
                                </div>
                                
                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Fecha de nacimiento</p>
                                    <input style={subToEditData?.birth_date !== subEditedData?.birth_date ? {border:'solid 4px red'} : {border:'none'}} className={style.edit_input} name='birth_date' value={subEditedData?.birth_date || ''} onChange={(e) =>handleSubEditingData(e)}/>
                                </div>

                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Género</p>
                                    <select style={subToEditData?.gender !== subEditedData?.gender ? {border:'solid 4px red'} : {border:'none'}} className={`${style.edit_selector} ${style.input}`} name='gender' onChange={(e) =>handleSubEditingData(e)}>
                                        <option selected={subToEditData?.gender === null || subEditedData?.gender === 'undefined' ? true : false } value=''> ------- </option>
                                        
                                        <option selected={subEditedData?.gender === 'masculino' ? true : false} value='masculino'>masculino</option>
                                        <option selected={subEditedData?.gender === 'femenino' ? true : false}  value='femenino'>femenino</option>
                                        <option selected={subEditedData?.gender === 'otro' ? true : false}      value='otro'>otro</option>
                                    </select>
                                </div>

                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Correo electrónico</p>
                                    <input style={subToEditData?.email !== subEditedData?.email ? {border:'solid 4px red'} : {border:'none'}} className={style.edit_input} name='email' value={subEditedData?.email || ''} onChange={(e) =>handleSubEditingData(e)}/>
                                </div>
                          
                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Teléfono</p>
                                    <input style={subToEditData?.phone !== subEditedData?.phone ? {border:'solid 4px red'} : {border:'none'}} className={style.edit_input} name='phone' type='tel' value={subEditedData?.phone || ''} onChange={(e) =>handleSubEditingData(e)}/>
                                </div>

                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Personal</p>
                                    <select style={subToEditData?.personal !== subEditedData?.personal ? {border:'solid 4px red'} : {border:'none'}} className={`${style.edit_selector} ${style.input}`} name='personal' onChange={(e) =>handleSubEditingData(e)}>
                                        <option selected={subToEditData?.personal === null || subEditedData?.task === 'undefined' ? true : false } value=''> ------- </option>

                                        <option selected={subToEditData?.personal === 'oficial' ? true : false} value='oficial'>oficial</option>
                                        <option selected={subToEditData?.personal === 'suboficial' ? true : false}  value='suboficial'>suboficial</option>
                                        <option selected={subToEditData?.personal === 'civil' ? true : false}      value='civil'>civil</option>
                                    </select>
                                </div>
                                
                                {
                                    subEditedData?.personal === 'oficial'
                                    &&
                                    <div className={style.input_text_container}>
                                        <p className={style.modal_text}>Jerarquía</p>
                                        <select style={subToEditData?.hierarchy !== subEditedData?.hierarchy ? {border:'solid 4px red'} : {border:'none'}} className={`${style.edit_selector} ${style.input}`} name='hierarchy' onChange={(e) =>handleSubEditingData(e)}>
                                            <option selected={subToEditData?.hierarchy === null || subEditedData?.hierarchy === 'undefined' ? true : false } value=''> ------- </option>
                                            
                                            <option selected={subToEditData?.hierarchy === 'Almirante' ? true : false}value="Almirante">Almirante</option>
                                            <option selected={subToEditData?.hierarchy === 'Vicealmirante' ? true : false}value="Vicealmirante">Vicealmirante</option>
                                            <option selected={subToEditData?.hierarchy === 'Contraalmirante' ? true : false}value="Contraalmirante">Contraalmirante</option>
                                            <option selected={subToEditData?.hierarchy === 'Comodoro de Marina' ? true : false}value="Comodoro de Marina">Comodoro de Marina</option>
                                            
                                            <option selected={subToEditData?.hierarchy === 'Capitán de Navío' ? true : false}value="Capitán de Navío">Capitán de Navío</option>
                                            <option selected={subToEditData?.hierarchy === 'Capitán de Fragata' ? true : false}value="Capitán de Fragata">Capitán de Fragata</option>
                                            <option selected={subToEditData?.hierarchy === 'Capitán de Corbeta' ? true : false}value="Capitán de Corbeta">Capitán de Corbeta</option>

                                            <option selected={subToEditData?.hierarchy === 'Teniente de Navío' ? true : false}value="Teniente de Navío">Teniente de Navío</option>
                                            <option selected={subToEditData?.hierarchy === 'Teniente de Fragata' ? true : false}value="Teniente de Fragata">Teniente de Fragata</option>
                                            <option selected={subToEditData?.hierarchy === 'Teniente de Corbeta' ? true : false}value="Teniente de Corbeta">Teniente de Corbeta</option>
                                            <option selected={subToEditData?.hierarchy === 'Guardiamarina' ? true : false}value="Guardiamarina">Guardiamarina</option>
                                            <option selected={subToEditData?.hierarchy === 'Cadete' ? true : false}value="Cadete">Cadete</option>

                                        </select>
                                    </div>
                                }

                                {
                                    subEditedData?.personal === 'oficial'
                                    &&
                                    <div className={style.input_text_container}>
                                        <p className={style.modal_text}>Cuerpo</p>
                                        <select style={subToEditData?.body !== subEditedData?.body ? {border:'solid 4px red'} : {border:'none'}} className={`${style.edit_selector} ${style.input}`} name='body' onChange={(e) =>handleSubEditingData(e)}>
                                            <option selected={subToEditData?.body === null || subEditedData?.body === 'undefined' ? true : false } value=''> ------- </option>
                                            
                                            <option selected={subToEditData?.body === 'Comando Naval' ? true : false} value="Comando Naval">Comando Naval</option>
                                            <option selected={subToEditData?.body === 'Comando Profesional' ? true : false} value="Profesional">Profesional</option>
                                        </select>
                                    </div>
                                }

                                {
                                    subEditedData?.personal === 'suboficial'
                                    &&
                                    <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Jerarquía</p>
                                        <select style={subToEditData?.hierarchy !== subEditedData?.hierarchy ? {border:'solid 4px red'} : {border:'none'}} className={`${style.edit_selector} ${style.input}`} name='hierarchy' onChange={(e) =>handleSubEditingData(e)}>
                                            <option selected={subToEditData?.hierarchy === null || subEditedData?.hierarchy === 'undefined' ? true : false } value=''> ------- </option>

                                            <option selected={subToEditData?.hierarchy === 'Suboficial Mayor' ? true : false} value="Suboficial Mayor">Suboficial Mayor</option>
                                            <option selected={subToEditData?.hierarchy === 'Suboficial Principal' ? true : false} value="Suboficial Principal">Suboficial Principal</option>
                                            <option selected={subToEditData?.hierarchy === 'Suboficial Primero' ? true : false} value="Suboficial Primero">Suboficial Primero</option>
                                            <option selected={subToEditData?.hierarchy === 'Suboficial Segundo' ? true : false} value="Suboficial Segundo">Suboficial Segundo</option>
                                            <option selected={subToEditData?.hierarchy === 'Cabo Principal' ? true : false} value="Cabo Principal">Cabo Principal</option>
                                            <option selected={subToEditData?.hierarchy === 'Cabo Primero' ? true : false} value="Cabo Primero">Cabo Primero</option>
                                            <option selected={subToEditData?.hierarchy === 'Cabo Segundo' ? true : false} value="Cabo Segundo">Cabo Segundo</option>
                                            <option selected={subToEditData?.hierarchy === 'Marinero Primero' ? true : false} value="Marinero Primero">Marinero Primero</option>
                                            <option selected={subToEditData?.hierarchy === 'Marinero Segundo' ? true : false} value="Marinero Segundo">Marinero Segundo</option>
                                            <option selected={subToEditData?.hierarchy === 'Aspirante' ? true : false} value="Aspirante">Aspirante</option>
                                        </select>
                                    </div>
                                }

                                {
                                    subEditedData?.personal === 'oficial'
                                    &&
                                    <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Especialidad</p>
                                        <select style={subToEditData?.task !== subEditedData?.task ? {border:'solid 4px red'} : {border:'none'}} className={`${style.edit_selector} ${style.input}`} name='task' onChange={(e) =>handleSubEditingData(e)}>
                                            <option selected={subToEditData?.task === null || subEditedData?.task === 'undefined' ? true : false } value=''> ------- </option>

                                            <option selected={subToEditData?.task === 'Apoyo' ? true : false} value="Apoyo">Apoyo</option>
                                            <option selected={subToEditData?.task === 'Auditoría' ? true : false} value="Auditoría">Auditoría</option>
                                            <option selected={subToEditData?.task === 'Clero castrense' ? true : false} value="Clero castrense">Clero castrense</option>
                                            <option selected={subToEditData?.task === 'Complementario' ? true : false} value="Complementario">Complementario</option>
                                            <option selected={subToEditData?.task === 'Ejecutivo' ? true : false} value="Ejecutivo">Ejecutivo</option>
                                            <option selected={subToEditData?.task === 'Especial' ? true : false} value="Especial">Especial</option>
                                            <option selected={subToEditData?.task === 'Infanteria de Marina' ? true : false} value="Infanteria de Marina">Infanteria de Marina</option>
                                            <option selected={subToEditData?.task === 'Ingeniería' ? true : false} value="Ingeniería">Ingeniería</option>
                                            <option selected={subToEditData?.task === 'Intendencia' ? true : false} value="Intendencia">Intendencia</option>
                                            <option selected={subToEditData?.task === 'Naval' ? true : false} value="Naval">Naval</option>
                                            <option selected={subToEditData?.task === 'Sanidad farmacia y bioquímica' ? true : false} value="Sanidad farmacia y bioquímica">Sanidad farmacia y bioquímica</option>
                                            <option selected={subToEditData?.task === 'Sanidad medicina' ? true : false} value="Sanidad medicina">Sanidad medicina</option>
                                            <option selected={subToEditData?.task === 'Sanidad odontología' ? true : false} value="Sanidad odontología">Sanidad odontología</option>
                                            <option selected={subToEditData?.task === 'Técnico' ? true : false} value="Técnico">Técnico</option>
                                            <option selected={subToEditData?.task === 'otro' ? true : false} value="otro">Otro</option>
                                        </select>
                                    </div>
                                }

                                {
                                    subEditedData?.personal === 'suboficial'
                                    &&
                                    <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Especialidad</p>
                                        <select style={subToEditData?.task !== subEditedData?.task ? {border:'solid 4px red'} : {border:'none'}} className={`${style.edit_selector} ${style.input}`} name='task' onChange={(e) =>handleSubEditingData(e)}>
                                            <option selected={subToEditData?.task === null || subEditedData?.task === 'undefined' ? true : false } value=''> ---- </option>

                                            <option selected={subToEditData?.task === 'Aeronáutico' ? true : false } value="Aeronáutico">Aeronáutico</option>
                                            <option selected={subToEditData?.task === 'Apoyo General' ? true : false } value="Apoyo General">Apoyo General</option>
                                            <option selected={subToEditData?.task === 'Arsenal' ? true : false } value="Arsenal">Arsenal</option>
                                            <option selected={subToEditData?.task === 'Buceo' ? true : false } value="Buceo">Buceo</option>
                                            <option selected={subToEditData?.task === 'Comunicaciones' ? true : false } value="Comunicaciones">Comunicaciones</option>
                                            <option selected={subToEditData?.task === 'Electricista' ? true : false } value="Electricista">Electricista</option>
                                            <option selected={subToEditData?.task === 'Enfermero' ? true : false } value="Enfermero">Enfermero</option>
                                            <option selected={subToEditData?.task === 'Furriel' ? true : false } value="Furriel">Furriel</option>
                                            <option selected={subToEditData?.task === 'General' ? true : false } value="General">General</option>
                                            <option selected={subToEditData?.task === 'Informaciones' ? true : false } value="Informaciones">Informaciones</option>
                                            <option selected={subToEditData?.task === 'Infantería de Marina' ? true : false } value="Infantería de Marina">Infantería de Marina</option>
                                            <option selected={subToEditData?.task === 'Informático' ? true : false } value="Informático">Informático</option>
                                            <option selected={subToEditData?.task === 'Mar' ? true : false } value="Mar">Mar</option>
                                            <option selected={subToEditData?.task === 'Maquinista' ? true : false } value="Maquinista">Maquinista</option>
                                            <option selected={subToEditData?.task === 'Músico' ? true : false } value="Músico">Músico</option>
                                            <option selected={subToEditData?.task === 'Mecánico de Sistemas' ? true : false } value="Mecánico de Sistemas">Mecánico de Sistemas</option>
                                            <option selected={subToEditData?.task === 'Operaciones' ? true : false } value="Operaciones">Operaciones</option>
                                            <option selected={subToEditData?.task === 'Servicios Hidrográficos' ? true : false } value="Servicios Hidrográficos">Servicios Hidrográficos</option>
                                            <option selected={subToEditData?.task === 'Servicios' ? true : false } value="Servicios">Servicios</option>
                                            <option selected={subToEditData?.task === 'Tropa Voluntario' ? true : false } value="Tropa Voluntario">Tropa Voluntario</option>
                                            <option selected={subToEditData?.task === 'Otro' ? true : false } value="otro">Otro</option>
                                        </select>
                                    </div>
                                }

                                <div className={style.input_text_container}>
                                    <p className={style.modal_text}>Destino</p>
                                    <select style={subToEditData?.destinationId !== subEditedData?.destinationId ? {border:'solid 4px red'} : {border:'none'}} className={`${style.edit_selector} ${style.input}`} name='destinationId' onChange={(e) =>handleSubEditingData(e)}>
                                        <option selected disabled defaultValue={null}>seleccionar</option>
                                        {
                                            destinations.map( ({ id, cuatrigrama, description }) => {
                                                return (
                                                    <option key={id} selected={subToEditData.destinationId === id ? true : false} value={id}>{`${cuatrigrama} - ${description}`}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                          

                            </div>

                            <div className={style.modal_openclose_buttons }>
                                    <button className={style.cancel} onClick={handleAbortSubscriberEdition}>cancelar</button>
                                
                                        {
                                            Object.getOwnPropertyNames(subEditedDataToSend).length !== 0
                                            ?
                                            <button className={style.submit} disabled={isDisabled} onClick={handleSendEditedSubDataToApi}>ACEPTAR</button>
                                            :
                                            <button className={style.disabled}>ACEPTAR</button>
                                        }
                                       
                            </div>
                        </div>
                    </div>
                </>
            }
        </MainLayOut>
    )
};

export default SubscribersList;