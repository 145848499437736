import { LogoAra } from '../../assets';

import style from './footer.module.scss';


const Footer = () => {
    return (
        <div className={style.footer}>
            <LogoAra width="70px" opacity="60%"/>
            <p className={style.footer_text}>
                Gaceta Marinera, portal de noticias de la Armada Argentina
            </p>
        </div>
    );
}

export default Footer;
