import config from '../../config';
import { useEffect, useContext, useState } from 'react';

import { UserContext, ThemeContext, ModalContext } from '../../contexts';

import { useNavigateTo } from '../../hooks';

import { updateAdminThemeAPI } from '../../services';
import { error_type } from '../../models';

import { ToggleThemeSwitch } from '../index';
import { GmLogo, GmIcon, LogoutIcon } from "../../assets";

import style from './header.module.scss';
import { privateRoutes } from '../../routes';




const Header = () => {

    const [ goTo ] = useNavigateTo();

    const { user, token, deleteUser, deleteToken } = useContext(UserContext);
    const { theme, darkLight, changeTheme, deleteDarkLight } = useContext(ThemeContext);
    const { activateNotification } = useContext(ModalContext);

    const [ closeOnOff, setCloseOnOff ] = useState(false);
    const [ openCloseModal , setOpenCloseModal ] = useState(false);

    const [ isScrolling, setIsScrolling ] = useState(false);


    const handleCloseSessionModal = () => { setOpenCloseModal(!openCloseModal); return; };
    const handleCloseSession = () => { deleteToken(); deleteUser(); deleteDarkLight(); return; };
    
    const handleSaveThemeOnProfile = () => {
        changeTheme();
        const darkTheme = !darkLight;
        updateAdminThemeAPI({ darkTheme }, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.CONTENTNOTUPDATED) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken();
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            return;
        })
    };

    useEffect(()=> {
        window.onscroll = () => {
            if (window.scrollY >= 70) {
                setIsScrolling(true);
            } else {
                setIsScrolling(false);
                return;
            }
        }
    }, []);


    return (
        <header className={isScrolling ? `${style.header} ${style.shadow_on}` : `${style.header} ${style.shadow_off}`} style={{backgroundColor:`${theme.darkBackground}`}}>
            <GmLogo clase={style.logo} width="240px" dark={theme.blackwhite}/>
            <GmIcon clase={style.icono} width="80px" dark={theme.blackwhite}/>

             <div className={style.user_data_container} onMouseEnter={() => setCloseOnOff(true)} onMouseLeave={() => setCloseOnOff(false)}>
                <div className={style.user_name_role} style={{color:`${theme.text}`}}>
                    Hola 👋🏻 {user.firstName}
                    <div>{user.role}</div>
                </div>
                <img className={style.user_image}src={`${config.api_profile}/${user.image}`}></img>
                
                <div className={ closeOnOff ? `${style.user_popup_menu} ${style.on}` : `${style.user_popup_menu} ${style.off}`}>
                    <div className={style.user_popup_menu_item}>
                        <p onClick={handleSaveThemeOnProfile}>modo oscuro</p>
                        <ToggleThemeSwitch handleSaveThemeOnProfile={handleSaveThemeOnProfile} darkLight={darkLight} />
                    </div>
                    <div className={style.user_popup_menu_item} onClick={()=>goTo(privateRoutes.SETTINGS)}>
                        <p>ajustes de cuenta</p>
                    </div>
                    <div className={style.user_popup_menu_item} onClick={handleCloseSessionModal}>
                        <p>cerrar sesión</p>
                    </div>
                </div>

            </div>
            
            <div className={openCloseModal ? `${style.close_session_modal} ${style.on}` : `${style.close_session_modal} ${style.off}`}>
                <div className={style.modal_openclose} style={{background: `${theme.darkBackground}`}}>
                    
                    <div className={style.modal_openclose_text} style={{borderBottom: `dotted 1px ${theme.text}` }}>
                        <div style={{padding:'16px'}}>
                            <LogoutIcon fill={theme.primary} />
                        </div>
                        <p style={{color:`${theme.text}`}} className={style.modal_openclose_message}>Estás por cerrar la sesión de</p>
                        <p style={{color:`${theme.text}`}} className={style.modal_openclose_user_email}>{user.email}</p>
                    </div>

                    <div className={style.modal_openclose_buttons}>
                        <button className={style.cancel} onClick={handleCloseSessionModal}>
                            cancelar
                        </button>
                        <button className={style.submit} onClick={handleCloseSession}>
                            ACEPTAR
                        </button>
                    </div>

                </div>
            </div>

        </header>
    )
};

export default Header;
