const AddIcon = ({ width = "36px", height = "36px", fill="#52cc0a" }) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 40 40" xmlSpace="preserve" width={width} height={height} fill={fill}>
            <g>
                <polygon points="40,16.6 23.4,16.6 23.4,0 16.6,0 16.6,16.6 0,16.6 0,23.4 16.6,23.4 16.6,40 23.4,40 23.4,23.4 40,23.4"/>
            </g>
        </svg>
    );
};

export default AddIcon;