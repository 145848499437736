import { useContext } from 'react';
import { AppContext, ThemeContext } from '../../contexts';

import { useNavigateTo } from '../../hooks';
import { privateRoutes } from '../../routes';

import { DashboardIcon, PublicationIcon, SectionIcon, CategoryIcon, TagIcon, SidebarIcon, AnaliticsIcon } from '../../assets';

import style from './sidebar.module.scss';


const Sidebar = ({children}) => {

    const { selectedMenuItem, setSelectedMenuItem } = useContext(AppContext);
    const { theme } = useContext(ThemeContext);
    
    const [ goto ] = useNavigateTo();

    return (
        <div className={style.main_container} style={{backgroundColor: `${theme.darkBackground}`}}>
            <div className={style.side_bar} style={{backgroundColor: `${theme.background}`}}>
                
                <div className={selectedMenuItem === 'suscriptores' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{ setSelectedMenuItem('suscriptores'); goto(privateRoutes.SUBSCRIBERS)}}>
                    <DashboardIcon width="18px" height="18px" fill={selectedMenuItem === 'suscriptores' ? theme.primary : theme.placeholder}/>
                    <p
                        // style={{color: selectedMenuItem === 'suscriptores' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Suscriptores
                    </p>
                </div>

    
                <div className={selectedMenuItem === 'newsubscriber' ? `${style.sidebar_button_container} ${style.black}` : style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('newsubscriber'); goto(privateRoutes.NEWSUBSCRIBER)}}>
                    <PublicationIcon width="18px" height="18px" fill={selectedMenuItem === 'newsubscriber' ? theme.primary : theme.placeholder} />
                    <p
                        // style={{color: selectedMenuItem === 'publications' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Nuevo
                    </p>
                </div>

                {/* <div className={selectedMenuItem === 'modifysubs' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('modifysubs'); goto(privateRoutes.MODIFYSUBSCRIBER)}}>
                    <SectionIcon width="18px" height="18px" fill={selectedMenuItem === 'modifysubs' ? theme.primary : theme.placeholder} />
                    <p
                        style={{color: selectedMenuItem === 'sections' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Modificar
                    </p>
                </div> */}

                <div className={selectedMenuItem === 'sections' ? `${style.sidebar_button_container} ${style.black}` :style.sidebar_button_container} onClick={()=>{setSelectedMenuItem('sections'); goto(privateRoutes.SECTIONS)}}>
                    <SectionIcon width="18px" height="18px" fill={selectedMenuItem === 'sections' ? theme.primary : theme.placeholder} />
                    <p
                        // style={{color: selectedMenuItem === 'sections' ? 'white' : `${theme.alttext}`}}
                        className={style.sidebar_button}
                        >
                        Suscripciones
                    </p>
                </div>

            </div>

            <div className={style.content}>
                {children}
            </div>
        </div>
    )
};

export default Sidebar;
