const LogoutIcon = ({ width = "40px", height = "40px", fill="white" }) => {
    return (
        <svg x="0px" y="0px" viewBox="0 0 40 40" xmlSpace="preserve" width={width} height={height} fill={fill}>
            <g>
                <path d="M25.5,34.8H5.2V5.2h20.3c1.1,0,2-0.9,2-2s-0.9-2-2-2H3.2c-1.1,0-2,0.9-2,2v33.6c0,1.1,0.9,2,2,2h22.3c1.1,0,2-0.9,2-2
                    S26.6,34.8,25.5,34.8z"/>
                <path d="M37.2,19.6c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-9.2-9.2
                    c-0.7-0.7-1.9-0.7-2.6,0c-0.7,0.7-0.7,1.9,0,2.6l6.1,6.1H15.7c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8H31L25,27.9
                    c-0.7,0.7-0.7,1.9,0,2.6c0.4,0.4,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5l9.2-9.2c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0-0.1
                    c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.4C37.2,19.9,37.2,19.8,37.2,19.6z"/>
            </g>
        </svg>
    );
};

export default LogoutIcon;