import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AltLayout } from '../../layouts';
import { AltLoader } from '../../assets';

import { verifyEmailAccountAPI } from '../../services';

import style from './verify.module.scss';



const  VerifyMailAccount = () => {

    const [ message, setMessage ] = useState('Estamos verificando tu dirección de mail, por favor no cierres esta ventana');
    const [ response, setResponse ] = useState(false);

    const [ searchParams ] = useSearchParams();
    const token = searchParams.get("token");
    

    useEffect( () => {
        verifyEmailAccountAPI(token)
        .then( res => {
            setResponse(true);
            setMessage(res.message);
        })
    }, []);


    return (
        <AltLayout>
            <p className={style.main_text}>
                {message}
            </p>
            {
                response === false
                &&
                <AltLoader />
            }
        </AltLayout>
    )
};


export default VerifyMailAccount;
